import React, { useEffect, useState } from 'react'
import { Modal } from "react-bootstrap";
import './signUp.scss';
import logo from "../assets/img/logo.svg";
import cloce_icon from "../assets/img/icon/close_icon.svg"
import { useDispatch, useSelector } from 'react-redux';
import { signUpInTabs } from '../reducers/singnUpSlice';
const SignUp = (props) => {
    const signUpIn = useSelector((state) => state.signUpInfo);
    const tabs = signUpIn?.signUpInTabs
    const [valid, setValid] = useState(false);
    const [userName, setUserName] = useState("");
    const [mobileNumber, setMobileNumber] = useState("");
    const [emailId, setEailId] = useState("");
    const [otpVerify, setOptVarified] = useState(false);
    const [otpWent, setOtpWent] = useState(false);
    const dispatch = useDispatch();
    useEffect(() => {
        if (props.fromLogin) {
            dispatch(signUpInTabs(tabs))
        } else {
            dispatch(signUpInTabs('signup'))
        }
    }, [])
    const links = [
        {
            id: 1,
            name: 'Sign Up',
            to: 'signup'
        },
        {
            id: 2,
            name: 'Sign In',
            to: 'signin'
        }
    ]

    const tabEvent = (tabValue) => {
        dispatch(signUpInTabs(tabValue));
    }
    console.log(tabs)
    const signUpAction = () => {
        setValid(true);
    }
    const verifyOtp = () => {
        setOptVarified(true);
    }
    const agreeTerms = () => {
        localStorage.setItem('authenticated', true);

        window.location.href = '/dashboard'

    }
    const getOtpAction = () => {
        setOtpWent(true);
    }
    const redirectToDashBoard = () => {
        localStorage.setItem('authenticated', true);
        props.closeSignUp();
        window.location.href = '/dashboard'
    }
    const closePopUp = (e) => {
        dispatch(signUpInTabs('signup'));
        props.closeSignUp();
        e.preventDefault();
    }
    const handleMobileNumberChange = (e) => {
        const inputMobileNumber = e.target.value.replace(/[^\d]/g, "");
        if (inputMobileNumber.length <= 10) {
            setMobileNumber(inputMobileNumber);
        }
    }
    return (
        <Modal
            show={props.showSignUp}
            close={props.closeSignUp}
            dialogClassName="signup-popup-modal"
        >
            <div className="modal-body singup-body">
                <form>
                    <div className="d-flex align-items-center justify-content-between modal_common_header">
                        <h5 className="modal-title" id="staticBackdropLabel">
                            <div className='gold-brick-log'>
                                <img src={logo} alt="logo" />
                            </div>
                        </h5>
                        <button
                            style={{ border: 'none', background: 'none' }}
                            onClick={(e) => {
                                closePopUp(e)
                            }}
                        >
                            <img src={cloce_icon} alt="image" className="close_icon" />
                        </button>
                    </div>
                    <div className="signup-modal-scroll" id="scroll_style">
                        <div className='row'>
                            <div className='col-lg-6'>
                                <div className='signup-slide'>
                                    <p className='goldbrick-text'>Join the GoldBreak community today and showcase your premium properties to a targeted and relevant audience!</p>
                                </div>
                            </div>
                            <div className='col-lg-5'>
                                <div className="bloc-tab">
                                    <ul
                                        className="nav nav-tabs singup-tabs"
                                        id="myTab"
                                        role="tablist"
                                    >
                                        {links.map((link) => {
                                            return (
                                                <li key={link.id} className="nav-item ">
                                                    <a
                                                        className={
                                                            "nav-link" + (
                                                                tabs == link.to ? " active" : "")
                                                        }
                                                        href={"#" + link.to}
                                                        role="tab"
                                                        aria-controls="home"
                                                        data-bs-toggle="tab"
                                                        onClick={() => tabEvent(link.to)}
                                                    >
                                                        {link.name}
                                                    </a>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </div>
                                {!valid && tabs == 'signup' ?
                                    <div className='signup-form'>
                                        <div class="form-group">
                                            <label for="formGroupExampleInput" className='labels'>Name
                                                <span className='asteric'>*</span></label>
                                            <input type="text" class="form-control" id="username" />
                                        </div>
                                        <div class="form-group">
                                            <label for="formGroupExampleInput2" className='labels'>Mobile Number
                                                <span className='asteric'>*</span></label>
                                            <input type="tel" class="form-control" id="mobileNumber"
                                                maxLength={10} value={mobileNumber}
                                                onChange={handleMobileNumberChange} />
                                        </div>
                                        <div class="form-group">
                                            <label for="formGroupExampleInput2" className='labels'>Email ID
                                                <span className='asteric'>*</span></label>
                                            <input type="email" class="form-control" id="emaiId" />
                                        </div>
                                        <button type="button" className="btn-lg btn-block signup-btn"
                                            onClick={signUpAction}>
                                            <span className='submit-text'>Sign Up</span>
                                        </button>
                                    </div>
                                    : !otpVerify && tabs == 'signup' ?
                                        <div className='signup-otp'>
                                            <div className='verify-div'>
                                                <p className='mobileNum'>We Have sent OTP to 9640939391</p>
                                                <p className='mobileNum'>Please Enter and Verify your Account</p>
                                            </div>
                                            <div className='otp-form'>
                                                <div class="form-group">
                                                    <div className='d-flex justify-content-between align-items-center'>
                                                        <label for="formGroupExampleInput" className='labels'>Enter OTP
                                                            <span className='asteric'>*</span></label>
                                                        <span>Time</span>
                                                    </div>
                                                    <input type="text" class="form-control" id="username" />
                                                </div>
                                                <button type="button" className="btn-lg btn-block signup-btn"
                                                    onClick={verifyOtp}>
                                                    <span className='submit-text'>Verify</span>
                                                </button>
                                            </div>
                                        </div>
                                        : tabs == 'signup' ?
                                            <div className='agree-terms'>
                                                <div className='verify-div'>
                                                    <p className='mobileNum'>
                                                        Just 25 villas set in 5 delightfully landscaped acres.
                                                        Villas that spell luxury and fi ne living in every built-up square foot.
                                                        Situated adjacent to Prestige North West County and a 5-minute walk from Angsana Oasis Spa & Resort,
                                                        just off Doddaballapura Main Road, in Bengaluru.
                                                    </p>
                                                    <p className='plicy-terms'>I Agree to GoldBreak <span>
                                                        <a href=''>T&C</a>, <a href=''>Privacy Policy & Cookie Policy</a></span></p>
                                                </div>
                                                <div className='otp-form'>
                                                    <button type="button" className="btn-lg btn-block signup-btn"
                                                        onClick={agreeTerms}>
                                                        <span className='submit-text'>I Agree</span>
                                                    </button>
                                                </div>
                                            </div> : ''
                                }
                                <div>
                                    {!otpWent && tabs == 'signin' ?
                                        <div className='signin-form'>
                                            <div class="form-group login-form">
                                                <label for="formGroupExampleInput2" className='labels'>Mobile Number
                                                    <span className='asteric'>*</span></label>
                                                <input type="text" class="form-control" id="mobileNumber" />
                                            </div>
                                            <div className='login-div'>
                                                <button type="button" className="btn-lg btn-block signup-btn"
                                                    onClick={getOtpAction}>
                                                    <span className='submit-text'>Next</span>
                                                </button>
                                            </div>
                                        </div>
                                        : otpWent && tabs == 'signin' ?
                                            <div className='signup-otp'>
                                                <div className='verify-div'>
                                                    <p className='mobileNum'>We Have sent OTP to 9640939391</p>
                                                    <p className='mobileNum'>Please Enter and Verify your Account</p>
                                                </div>
                                                <div className='otp-form'>
                                                    <div class="form-group">
                                                        <div className='d-flex justify-content-between align-items-center'>
                                                            <label for="formGroupExampleInput" className='labels'>Enter OTP
                                                                <span className='asteric'>*</span></label>
                                                            <span>Time</span>
                                                        </div>
                                                        <input type="text" class="form-control" id="username" />
                                                    </div>
                                                    <button type="button" className="btn-lg btn-block signup-btn"
                                                        onClick={redirectToDashBoard}>
                                                        <span className='submit-text'>Next</span>
                                                    </button>
                                                </div>
                                            </div>
                                            : ''
                                    }
                                </div>
                            </div>
                            <div className='col-lg-1'></div>
                        </div>
                    </div>
                </form>
            </div>
        </Modal>
    )
}

export default SignUp