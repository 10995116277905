import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { totalFloorNo, totalFloorType } from '../../reducers/postPropertySlice';

const TotalFloors = () => {
  const dispatch = useDispatch();
    const floorInfo = useSelector((state) => state.postProperties)
    const [buttons, setButtons] = useState([
        { name: 'Lower Basement', selected: false },
        { name: 'Upper Basement', selected: false },
        { name: 'Ground', selected: false },
    ]);
    const totalFloors=['1','2','3','4','5','6+'];
    const selectedFloorNo = floorInfo?.totalFloorNo;
    const handleButtonClick = (index, name) => {
        const updatedButtons = buttons.map((button, i) => {
            if (i === index) {
                dispatch(totalFloorType(name));
                return { ...button, selected: true };
            } else {
                return { ...button, selected: false };
            }
        });
        setButtons(updatedButtons);
    };
    const handleFloorNo =(index)=>{
        dispatch(totalFloorNo(index));
    }
  return (
    <div className='row roomsAndBalconies floor_base'>
      <div className='col-lg-5' style={{paddingLeft:'0px'}}>
        <p className='common_color'>Total Floors</p>
            {totalFloors.map((floorNo, index) => (  
            <button
                key={index}
                type="button"
                className={selectedFloorNo === index ? 'selected_value' : 'unselected_btn'}
                onClick={() => handleFloorNo(index)}
            >
                {floorNo}
            </button>
            ))}
        </div>
      <div className='col-lg-6' style={{paddingLeft:'0px'}}>
        <p className='common_color'>Furnished Status<span style={{color:'#EE2F2F'}}>*</span></p>
            {buttons.map((button, index) => (
                <button
                    key={index}
                    type="button"
                    className={button.selected ? 'selected_value' : 'unselected_btn'}
                    onClick={() => handleButtonClick(index,button.name)}
                >
                    {button.name}
                </button>
            ))}
      </div>
    </div>
  )
}

export default TotalFloors