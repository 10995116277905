import React, { useState } from 'react'
import dashbaord from '../../assets/img/icon/dashboard/dashboard.svg'
import enquiries from '../../assets/img/icon/dashboard/enquiries.svg'
import properties from '../../assets/img/icon/dashboard/properties.svg'
import myprofile from '../../assets/img/icon/dashboard/myprofile.svg'
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
import { linkPath, linkValue } from '../../reducers/navigationSlice'
const SideNavigation = () => {
    const links = [
        {
          id: 1,
          name: 'Dashboard',
          to: "/dashboard",
          className: "side_nav_item",
          img: dashbaord,
        },
        {
          id: 2,
          name: "Enquiries",
          to: "/enquiries",
          className: "side_nav_item",
          img: enquiries,
        },
        {
          id: 3,
          name: "Properties",
          to: "/properties",
          className: "side_nav_item",
          img: properties,
        },
        {
          id: 4,
          name: "My Profile",
          to: "/myprofile",
          className: "side_nav_item",
          img: myprofile,
        },
    ];

    const dispatch = useDispatch();
    const [linksData, setLinksData] = useState(links)
    const [isActive, setisActive] = useState(false);
    const handleToggle = () => {
        setisActive(!isActive);
        localStorage.setItem("isActiveMenu", !isActive);
    };
    const [activeLink, setactiveLink] = useState(0);
    const handleClick = (id, path) => {
        setactiveLink(id);
        dispatch(linkPath(path));
        dispatch(linkValue(id));
    };
    const pathsInfo = useSelector((state) => state.linkPathInfo);
    const getPathsId = () => {
        var id = 1;
        var linkPath = pathsInfo?.linkPath;
        switch (linkPath) {
          case "/dashboard":
            id = 1;
            break;
          case "/enquiries":
            id = 2;
            break;
          case "/properties":
            id = 3;
            break;
          case "/myprofile":
            id = 4;
            break;
        }
        return id;
    };
  return (
    <div>
    {/* <nav className="navbar navbar-default no-margin fixed-brand bg_white">
      <div className="navbar-header d-flex align-items-center ">
        <button
          className="navbar-toggle collapse in d-block"
          data-toggle="collapse"
          id="menu-toggle-2"
          onClick={handleToggle}
        >
        </button>
      </div>
    </nav> */}
    <div id="wrapper" className={isActive ? "toggled-2" : null}>
      <div id="sidebar-wrapper">
        <ul className="sidebar-nav nav-pills nav-stacked" id="scroll_style">
          {linksData.map((link, i) => {
            return (
              <li key={link.id}>
                <Link
                  onClick={() => handleClick(link.id, link.to)}
                  className={
                    link.className +
                    (link.id === (activeLink != 0 ? activeLink : getPathsId())
                      ? " active_item"
                      : "")
                  }
                  to={link.to}
                >
                  <div className="flex_class mr-0">
                    <div className="icons">
                      <img src={link.img} className="flex_class mx-auto mr-0" />
                    </div>
                    <span>{link.name} </span>
                  </div>
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  </div>
  )
}

export default SideNavigation