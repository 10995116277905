import React, { useState } from 'react'
import './dashboard.scss'
import home_color_icon from "../../assets/img/icon/home_color_icon.svg";
import { useNavigate } from 'react-router';
const Dashboard = () => {
  const navigate = useNavigate();
  const [data, setData] = useState(
    {
      id:1,
      name:'Pavan',
      noOfVisitors:20,
      noOfEnqueries:10,
      listedProperties:12,
      listProperties:15
    }
  )
  const gotoPostProperty = ()=>{
    navigate('/postproperty')
  }
  return (
    <div className="main_div_padding">
      {data.length>0?
      <div className='row dashboard-data'>
        <div className='col-lg-1'></div>
        <div className='col-lg-9' style={{width:'100%', paddingLeft:'0px'}}>
          <div className='row'>
          <div className='col-lg-3'>
            <div className='visitors_div'>
              <p className='header-tag'>{data?.noOfVisitors}</p>
              <p className='text_val'>Number o visitors</p>
            </div>
          </div>
          <div className='col-lg-3'>
            <div className='properties_div'>
              <p className='header-tag'>{data?.noOfEnqueries}</p>
              <p className='text_val'>Number of Enquiries</p>
            </div>
          </div>
          <div className='col-lg-3'>
          <div className='properties_div'>
              <p className='header-tag'>{data?.listedProperties}</p>
              <p className='text_val'>Listed Properties</p>
            </div>
          </div>
          <div className='col-lg-3'>
            <div className='properties_div'>
            <p className='header-tag'>{data?.listProperties}</p>
              <p className='text_val'>Listed Properties</p>
            </div>
          </div>
          </div>
        </div>
        <div className='col-lg-2'></div>
      </div>
      :
      <div className='row'>
        <div className='col-lg-1'></div>
        <div className='col-lg-9'>
          <div className='welcome_card'>
            <div className='wel_gold'>
              <h1 className='header-tag'>Welcome to GoldBrick!</h1>
              <p>Our platform is designed to help builders like you showcase your</p>
              <p> properties to a wider audience and connect with potential buyers who</p>
              <p> are interested in premium homes in sought-after locations.</p>
            </div>
          </div>
        </div>
        <div className='col-lg-2'></div>
      </div>}
      <div className='row'>
        <div className='col-lg-1'></div>
        <div className='col-lg-9'>
          <div className='property_card'>
            <div className='wel_gold'>
              <h1 className='header-tag'>Sell Property!</h1>
              <div className='d-flex justify-content-between'>
                <div>
                  <p>Our platform is designed to help builders like you showcase your</p>
                  <p> properties to a wider audience and connect with potential buyers who</p>
                  <p> are interested in premium homes in sought-after locations.</p>
                </div>
                
                <button className="primary_btn nav_btn" onClick={gotoPostProperty}>
                  <div className="d-flex align-items-center">
                    <img src={home_color_icon} className="" alt="image" />
                        Post Property
                  </div>
              </button>
              </div>
            </div>
          </div>
        </div>
        <div className='col-lg-2'></div>
      </div>
    </div>
  )
}

export default Dashboard