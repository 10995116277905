import React from "react";
import img1 from "../assets/img/home/img1.png";
import propertyLogo from "../assets/img/home/property_logo.svg";
import next_icon from "../assets/img/icon/next_icon.svg";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import CallToAction from "./call_to_action";
const RelatedProjects = () => {
  return (
    <div className="top_builders search_main_div">
      <div className="row">
        <div className="col-lg-8 pl-0">
          <div
            className="card"
            onClick={() => {
              //   singlePageView(item);
            }}
          >
            <div className="images_sec">
              <OwlCarousel
                items={1}
                className="owl-theme property_car"
                margin={20}
                loop
                dots={true}
                nav
              >
                <div className="item">
                  <img src={img1} className="main_img" alt="image" />
                </div>
                <div className="item">
                  <img src={img1} className="main_img" alt="image" />
                </div>
                <div className="item">
                  <img src={img1} className="main_img" alt="image" />
                </div>
              </OwlCarousel>

              <div className="logo_image">
                <img src={propertyLogo} alt="image" />
              </div>
            </div>

            <div className="card_body">
              <h5 className="card_head">Prestige Metropolitan</h5>
              <p className="grey_para">Anna Salai, Chennai</p>
              <div className="d-flex justify-content-between price_sec">
                <div>
                  <h5 className="grey_para">4.5 Acres</h5>
                  <p className="body_para bold_para">Starting from : --</p>
                </div>
                <img src={next_icon} />
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-4">
            <CallToAction btn_text="Talk to Us" />
        </div>
      </div>
    </div>
  );
};
export default RelatedProjects;
