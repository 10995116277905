import React from 'react'
import { Modal } from "react-bootstrap";
import cloce_icon from "../../assets/img/icon/close_icon.svg";
import './profileModal.scss';
import user_pic from "../../assets/img/icon/user_pic.svg";
import upload_icon from  '../../assets/img/icon/myprofile/upload_icon.svg'
const ProfileModalPopUp = (props) => {
    return (
      <Modal show={props.showModal} close={props.closeModal} dialogClassName="profile-popup-modal">
        <div className="modal-body profile_modal_body">
          <form>
            <div className="flex_class align-items-center justify-content-between modal_common_header">
              <h5 className="modal-title" id="staticBackdropLabel">My Profile info</h5>
              <button className='no_pad_btn justify-content-end' style={{ border: 'none', background: 'none' }} onClick={(e) => { props.closeModal() }}>
                <img src={cloce_icon} alt="image" className="close_icon" />
              </button>
            </div>
            <div className="profile-modal-scroll" id="scroll_style">
              <div className='row modal_con'>
                <div className='col-lg-12'>
                  <div className='row'>
                    <div className='col-lg-6'>
                      <div className="form-group">
                        <label htmlFor="username" className='labels'>Name<span style={{ color: '#222222' }}>*</span></label>
                        <input type="text" className="form-control" id="username" />
                      </div>
                      <div className="form-group">
                        <label htmlFor="mobileNumber" className='labels'>Mobile Number<span style={{ color: '#222222' }}>*</span></label>
                        <input type="tel" className="form-control" id="mobileNumber" />
                      </div>
                      <div className="form-group">
                        <label htmlFor="emaiId" className='labels'>Email ID<span style={{ color: '#222222' }}>*</span></label>
                        <input type="email" className="form-control" id="emaiId" />
                      </div>
                      <div className="form-group">
                        <label htmlFor="experience" className='labels'>Experience<span style={{ color: '#222222' }}>*</span></label>
                        <input type="email" className="form-control" id="experience" />
                      </div>
                    </div>
                    <div className='col-lg-6'>
                      <div className='form-group upload_pic'>
                        <div className='upload_pic d-flex align-items-center justify-content-center'>
                            <img src={user_pic} alt="user_profile" height={'100px'} width={'1000px'}/>
                        </div>
                        
                        <div className='flex_class upload_photo align-items-center justify-content-center'>
                            <button style={{border:'none', background:'none'}}>
                                <img src={upload_icon} alt="upload_icon" />
                            </button>
                            <p>Upload Your Photo</p>
                        </div>
                      </div>
                      <div className="form-group">
                        <label htmlFor="emaiId" className='labels'>City<span style={{ color: '#222222' }}>*</span></label>
                        <input type="text" className="form-control" id="city" />
                      </div>
                      <div className="form-group">
                        <label htmlFor="experience" className='labels'>Language<span style={{ color: '#222222' }}>*</span></label>
                        <input type="text" className="form-control" id="language" />
                      </div>
                    </div>
                  </div>
                  <div className='row about_inf'>
                    <div className='col-lg-12'>
                        <label htmlFor="username" className='labels'>About You<span style={{ color: '#222222' }}>*</span></label>
                        <div class="form-group">
                            <textarea class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
                        </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-lg-12'>
                    <div className='row justify-content-between flex_class align-items-center'>
                        <div className='col-lg-4' style={{paddingLeft:'0px'}}>
                            <div className='danger_text'>
                                <p>Please complete profile 100% info this will be shown in your profile</p>
                            </div> 
                        </div>
                        <div className='col-lg-6'></div>
                        <div className='col-lg-2'>
                            <button type='button' className='sumbit_btn' onClick={props.closeModal}>Submit</button>
                        </div>  
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </Modal>
    )
  }
  
  export default ProfileModalPopUp;
  