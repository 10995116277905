import React, { useState } from 'react'

const PropertyArea = () => {
    const [area, setArea] = useState('');
    const areaRequest = e => {
        setArea(e.target.value);
    }
    return (
        <div className='row'>
            <div className='col-lg-5'  style={{paddingLeft:'0px'}}>
                <div className='input_text'>
                    <p className='input_type_text'>Saleable area</p>
                </div>
                <div class="input-group">
                    <input type="text" class="form-control" aria-label="Text input with dropdown button"
                        placeholder='Rs.' onChange={(e) => areaRequest(e)} />
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">sq.feet</button>
                        <div class="dropdown-menu">
                            <a class="dropdown-item" href="#">Action</a>
                            <a class="dropdown-item" href="#">Another action</a>
                            <a class="dropdown-item" href="#">Something else here</a>
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-lg-5' style={{paddingLeft:'0px'}}>
                <div className='input_text'>
                    <p className='input_type_text'>Carpet area</p>
                </div>
                <div class="input-group">
                    <input type="text" class="form-control" aria-label="Text input with dropdown button"
                       placeholder='Rs.' onChange={(e) => areaRequest(e)} />
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">sq.feet</button>
                        <div class="dropdown-menu">
                            <a class="dropdown-item" href="#">Action</a>
                            <a class="dropdown-item" href="#">Another action</a>
                            <a class="dropdown-item" href="#">Something else here</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PropertyArea