import React, { useState } from "react";
import allNotifications from '../../assets/img/icon/myprofile/notification_icon.svg';
import emailNotification from '../../assets/img/icon/myprofile/emails_notify.svg';
const Settings = () => {
    const [notifications, setNotifications] = useState([
        {
            id: 1,
            img: allNotifications,
            notificationName: 'Notifications',
            text: 'By on switch you will receive all Notifications',
            status: false,
        },
        {
            id: 2,
            img: emailNotification,
            notificationName: 'Emails',
            text: 'By off switch you will Not receive any emails',
            status: false,
        },
    ]);

    const toggleNotificationStatus = (id) => {
        const updatedList = notifications.map((item) => {
          if (item.id === id) {
            return {
              ...item,
              status: !item.status,
            };
          }
          return item;
        });
        setNotifications(updatedList);
      };
    return (
        <div className="row">
            {notifications.map((item, index) => {
                return (
                    <div className="col-lg-8 flex_class notifications_settings justify-content-between">
                        <div className="col-lg-6">
                            <div className="flex_class">
                                <div className="notify_img">
                                    <img src={item.img} alt="notification_icon" />
                                </div>
                                <div className="notification_text">
                                    <h5>{item.notificationName}</h5>
                                    <p>{item.text}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2">
                            <div className="d-flex justify-content-between align-items-center">
                                <p className="mb-0">
                                    {item.status ? "ON" : "OFF"}
                                </p>
                                <div className="custom-control custom-switch">
                                    <input
                                        type="checkbox"
                                        className="custom-control-input"
                                        id={"customSwitches" + index}
                                        checked={item.status}
                                        onChange={() => toggleNotificationStatus(item.id)}
                                    />
                                    <label
                                        className={`custom-control-label ${item.status ? "#002F10;" : "#DDDDDD;"
                                            }`}
                                        htmlFor={"customSwitches" + index}
                                    ></label>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}
export default Settings;