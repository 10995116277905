import React from "react";
import ben_icon from "../assets/img/icon/benfites.svg";
import "./benefits.scss";
const BenefitsSec = () => {
  const benefits = [
    {
      id: 1,
      name: "Free property uploads",
      icon: ben_icon,
      text: "Builders can upload their properties for free on our platform and reach a wider audience.",
      cardAd: false,
    },
    {
      id: 2,
      name: "Free property uploads",
      icon: ben_icon,
      text: "Builders can upload their properties for free on our platform and reach a wider audience.",
      cardAd: false,
    },
    {
      id: 2,
      name: "Free property uploads",
      icon: ben_icon,
      text: "Builders can upload their properties for free on our platform and reach a wider audience.",
      cardAd: false,
    },
    {
      id: 2,
      name: "Free property uploads",
      icon: ben_icon,
      text: "Builders can upload their properties for free on our platform and reach a wider audience.",
      cardAd: false,
    },

    {
      id: 2,
      name: "Free property uploads",
      icon: ben_icon,
      text: "Builders can upload their properties for free on our platform and reach a wider audience.",
      cardAd: false,
    },
    {
      id: 2,
      name: "Free property uploads",
      icon: ben_icon,
      text: "Builders can upload their properties for free on our platform and reach a wider audience.",
      cardAd: false,
    },
    {
      id: 2,
      name: "Looking for premium homes in your city? ",
      icon: ben_icon,
      text: "Premier real estate platform that provides comprehensive services",
      cardAd: true,
    },
    {
      id: 2,
      name: "Free property uploads",
      icon: ben_icon,
      text: "Builders can upload their properties for free on our platform and reach a wider audience.",
      cardAd: false,
    },
  ];
  return (
    <div className="benefits_sec">
      <h2 className="section_head text-center">
        Benefits of using GoldBreak for builders
      </h2>
      <div className="row row_top">
        {benefits.map((item, i) => {
          return (
            <div className="col-lg-3">
              {!item.cardAd ? (
                <div className="card">
                  <img src={item.icon} className="" alt="image" />
                  <h5 className="card_head">{item.name}</h5>
                  <p className="body_para">{item.text}</p>
                </div>
              ) : (
                <div className="card text-center ad_card">
                  <h5 className="card_head white_color">{item.name}</h5>
                  <p className="body_para white_color">{item.text}</p>
                  <button className="primary_btn">Post Property</button>
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default BenefitsSec;
