import React, { useState } from 'react'
const ListBuy = () => {
    const [buyButton, setBuyButtons] = useState([
        { name: 'Residential', selected: false },
        { name: 'Commercial', selected: false },
    ]);
    const [listButton, setListButtons] = useState([
        { name: 'Freehold', selected: false },
        { name: 'Power of Attorney', selected: false },
    ]);
    const handleBuyButtonClick = (index, name) => {
        const updatedButtons = buyButton.map((button, i) => {
            if (i === index) {
                return { ...button, selected: true };
            } else {
                return { ...button, selected: false };
            }
        });
        setBuyButtons(updatedButtons);
    };
    const handleListButtonClick = (index, name) => {
        const updatedButtons = listButton.map((button, i) => {
            if (i === index) {
                return { ...button, selected: true };
            } else {
                return { ...button, selected: false };
            }
        });
        setListButtons(updatedButtons);
    };
    return (
        <div className='row listbuy_button'>
            <div className='col-lg-1'></div>
            <div className='col-lg-4'>
                <p className='common_color'>I want to Buy</p>
                <div className='d-flex'>
                {buyButton.map((button, index) => (
                <button
                    key={index}
                    type="button"
                    className={button.selected ? 'selected_value' : 'unselected_btn'}
                    onClick={() => handleBuyButtonClick(index,button.name)}
                >
                    {button.name}
                </button>
            ))}
            </div>
            </div>
            <div className='col-lg-4'>
                <p className='common_color'>Listed by</p>
                <div className='d-flex'>
                {listButton.map((button, index) => (
                <button
                    key={index}
                    type="button"
                    className={button.selected ? 'selected_value' : 'unselected_btn'}
                    onClick={() => handleListButtonClick(index,button.name)}
                >
                    {button.name}
                </button>
            ))}
            </div>
        </div>
        </div>
    )
}

export default ListBuy