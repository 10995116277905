import React, { Component } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./assets/css/style.scss";
import Footer from "./components/footer";
import Header from "./components/header";
import Home from "./modules/home";
import Dashboard from "./modules/dashboard-modules/dashboard";
import Layout from "./modules/dashboard-modules/layout";
import Enquiries from "./modules/enquirie/enquiries";
import Profile from "./modules/myprofile/profile";
import SearchByProperty from "./modules/search_by_property";
import OneProperty from "./modules/one_property/one_property";
import AgentProfilePage from "./modules/agent_profile_page";
import AgentHomePage from "./modules/agent_home_page";
import PostProperty from "./modules/properties/postProperty";
import Property from "./modules/properties/property";
const App = () => {
  const isAuthenticated = localStorage.getItem('authenticated');
  if(isAuthenticated){
    return(
      <BrowserRouter>
        <Layout>
          <Routes>
            <Route exact path ="/dashboard" element={<Dashboard />} />
            <Route exact path ="/enquiries" element={<Enquiries />} />
            <Route exact path ="/properties" element={<Property />} />
            <Route exact path="/postproperty" element={<PostProperty />} />
            <Route exact path ="/myprofile" element={<Profile />} />
          </Routes>
        </Layout>
      </BrowserRouter>
    )
  }
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route exact path="/"  element={<Home />}/>
          <Route exact path="/search-properties"  element={<SearchByProperty />}/>
          <Route exact path="/one-property"  element={<OneProperty />}/>
          <Route exact path="/agent-profile-view"  element={<AgentProfilePage />}/>
          <Route exact path="/agent-home-page"  element={<AgentHomePage />}/>
        </Routes>
      </BrowserRouter>
     
    </div>
  );
};
export default App;
