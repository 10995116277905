import { configureStore } from '@reduxjs/toolkit';
import filtersInfoReduer from './reducers/filtersInfoSlice';
import signUpInReducer from  './reducers/singnUpSlice';
import navigationReducer from './reducers/navigationSlice'
import postPropertyReducer from './reducers/postPropertySlice';
export default configureStore({
    reducer:{
        filtersInfo: filtersInfoReduer,
        signUpInfo: signUpInReducer,
        linkPathInfo: navigationReducer,
        postProperties:postPropertyReducer
    }
})