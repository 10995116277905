import React, { useState } from "react";
import home_color_icon from "../../assets/img/icon/home_color_icon.svg";
import no_data_available from "../../assets/img/icon/enquiries/no_data_available.svg";
import "./enquiry.scss";
import plat_img from "../../assets/img/icon/enquiries/plat_img.svg";
import view_btn from "../../assets/img/icon/next_icon.svg";
import down_arrow from "../../assets/img/icon/enquiries/down_Arrow.svg";
import { useNavigate } from "react-router";
import tick_mark from "../../assets/img/icon/enquiries/tick_mark.svg";
const Enquiries = () => {
  const agents = [
    {
      id: 1,
      name: "Pavan",
      mobile: "9000369106",
      email: "pavanrayipudi111@gmail.com",
      message: "I m looking for luxury 2bhk in hyderabad",
      status: "New Enquiry",
      date: new Date(),
      updateStatus: 1,
      show: false,
    },
    {
      id: 2,
      name: "Pavan",
      mobile: "9000369106",
      email: "pavanrayipudi111@gmail.com",
      message: "I m looking for luxury 2bhk in hyderabad",
      status: "New Enquiry",
      date: new Date().getTime,
      updateStatus: 2,
      show: false,
    },
  ];
  const [enquery, setEnquery] = useState([
    {
      id: 1,
      image: "",
      header: "Riverscape",
      text: "3, 4 BR Villas | Bandlaguda, TSPA Junction",
      agents: agents,
    },
    {
      id: 2,
      image: "",
      header: "Lake",
      text: "3, 4 BR Villas | Bandlaguda, TSPA Junction",
      agents: agents,
    },
  ]);

  const [tabs, setTabs] = useState("all");
  const tabEvent = (tabValue) => {
    setTabs(tabValue);
  };
  const links = [
    {
      id: 1,
      name: "All",
      to: "all",
    },
    {
      id: 2,
      name: "Today",
      to: "today",
    },
    {
      id: 3,
      name: "This Week",
      to: "thisweek",
    },
    {
      id: 4,
      name: "This Month",
      to: "thismonth",
    },
    {
      id: 5,
      name: "Active",
      to: "active",
    },
    {
      id: 6,
      name: "In active",
      to: "inactive",
    },
  ];
  const navigate = useNavigate();
  const [showInfo, setShowInfo] = useState(false);
  const showeCustomerInfo = () => {
    setShowInfo(true);
  };
  const hideCustomerInfo = () => {
    setShowInfo(false);
  };
  const navigatePostProperty = () => {
    navigate("/postproperty");
  };
  return (
    <div className="main_div_padding">
      {enquery.length > 0 ? (
        <div className="row">
          <div className="col-lg-1"></div>
          <div className="col-lg-10 enquery_details">
            <div>
              <div className="bloc-tab">
                <ul className="navs navs-tabs" id="myTab" role="tablist">
                  {links.map((link) => {
                    return (
                      <li key={link.id} className="nav-item ">
                        <a
                          className={
                            "navs-link" + (tabs == link.to ? " active" : "")
                          }
                          href={"#" + link.to}
                          role="tab"
                          aria-controls="home"
                          data-bs-toggle="tab"
                          onClick={() => tabEvent(link.to)}
                        >
                          {link.name}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div className="plot_info" id="scroll_style">
                {enquery.map((item, ind) => {
                  return (
                    <div className="property_val">
                      <div className="flex_class plot-details">
                        <img src={plat_img} alt="plot_img" />
                        <div className="plot_headers">
                          <h1>{item.header}</h1>
                          <p>{item.text}</p>
                        </div>
                      </div>
                      <div className="verrtical_line"></div>
                      <div>
                        {item.agents.length != 0 &&
                          item.agents.map((data, index) => {
                            const isLastItem = index === item.agents.length - 1;
                            return (
                              <div>
                                <div className="agent_info" id="accordion">
                                  <div className="row d-flex agent_dtl">
                                    <div className="col-lg-2 agent_name">
                                      <h1>{data.name}</h1>
                                      <p>{data.mobile}</p>
                                    </div>
                                    <div className="col-lg-2 agent_email_div">
                                      <h5>Email ID</h5>
                                      <p>{data.email}</p>
                                    </div>
                                    <div className="col-lg-3 agent_bio">
                                      <h5>Message</h5>
                                      <p>{data.message}</p>
                                    </div>
                                    <div className="col-lg-2 agent_bio">
                                      <h5>Status</h5>
                                      <p>{data.status}</p>
                                    </div>
                                    <div className="col-lg-2 agent_bio">
                                      <h5>Time</h5>
                                      <p>2023-05-03</p>
                                    </div>
                                    <div className="col-lg-1 arrow_button">
                                      <button
                                        style={{
                                          border: "none",
                                          background: "none",
                                        }}
                                        data-toggle="collapse"
                                        data-target={"#collapse" + ind + index}
                                        aria-expanded="true"
                                        aria-controls="collapseOne"
                                      >
                                        <img src={view_btn} alt="view_icon" />
                                      </button>

                                      {/* <button style={{ border: 'none', background: 'none' }} data-toggle="collapse">
                                        <img src={down_arrow} alt='view_icon' />
                                      </button> */}
                                    </div>
                                  </div>
                                  <div>
                                    <div
                                      className="row"
                                      id={"collapse" + ind + index}
                                      class="collapse"
                                      data-parent="#accordion"
                                    >
                                      <div className="horizontal_lie"></div>
                                      <div className="d-flex view_info">
                                        <div className="col-lg-4">
                                          <h1>Update Status</h1>
                                          <p>
                                            Your Customer will receive a SMS
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {!isLastItem && (
                                  <div className="verrtical_line"></div>
                                )}
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="col-lg-1"></div>
        </div>
      ) : (
        <div>
          <div className="row">
            <div className="col-lg-1"></div>
            <div className="col-lg-9">
              <div className="welcome_card">
                <div className="d-flex justify-content-between wel_gold">
                  <div>
                    <h1 className="header-tag">No Enquiries!</h1>
                    <p>No Data Available.</p>
                  </div>
                  <div>
                    <img src={no_data_available} alt="no _data" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-2"></div>
          </div>
          <div className="row">
            <div className="col-lg-1"></div>
            <div className="col-lg-9">
              <div className="property_card">
                <div className="wel_gold">
                  <h1 className="header-tag">Sell Property!</h1>
                  <div className="d-flex justify-content-between">
                    <div>
                      <p>
                        Our platform is designed to help builders like you
                        showcase your
                      </p>
                      <p>
                        {" "}
                        properties to a wider audience and connect with
                        potential buyers who
                      </p>
                      <p>
                        {" "}
                        are interested in premium homes in sought-after
                        locations.
                      </p>
                    </div>
                    <button
                      className="primary_btn nav_btn"
                      onClick={navigatePostProperty}
                    >
                      <div className="d-flex align-items-center">
                        <img src={home_color_icon} className="" alt="image" />
                        Post Property
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-2"></div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Enquiries;
