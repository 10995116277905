import axios from "axios";
// const isLocalAuth = localStorage.getItem("isauth");
// const loginData = JSON.parse(localStorage.getItem("loginResponse"));
// const clientId = isLocalAuth == "true" ? loginData.authKeys.clientId : "";
// const clientSecret =
//   isLocalAuth == "true" ? loginData.authKeys.clientSecret : "";
const instance = axios.create({
  baseURL: "http://ec2-43-205-112-185.ap-south-1.compute.amazonaws.com:8080/v1/",
});
instance.defaults.headers.common["Content-Type"] = "application/json";
export default instance;
