import { createSlice } from "@reduxjs/toolkit";

export const signUpSlice = createSlice({
    name:"signUpInfo",
    initialState:{
        signUpInTabs:""
    },
    reducers:{
        signUpInTabs:(state, action)=>{
            state.signUpInTabs = action.payload
        }
    }
})
export const {signUpInTabs} = signUpSlice.actions;
export default signUpSlice.reducer;