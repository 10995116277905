import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { floorNo, floorType } from '../../reducers/postPropertySlice';

const FloorNo = () => {
    const dispatch = useDispatch();
    const floorInfo = useSelector((state) => state.postProperties)
    const [buttons, setButtons] = useState([
        { name: 'Lower Basement', selected: false },
        { name: 'Upper Basement', selected: false },
        { name: 'Ground', selected: false },
    ]);
    const floorNos=['1','2','3','4','5'];
    const selectedFloorNo = floorInfo?.floorNo;
    const handleButtonClick = (index, name) => {
        const updatedButtons = buttons.map((button, i) => {
            if (i === index) {
                dispatch(floorType(name));
                return { ...button, selected: true };
            } else {
                return { ...button, selected: false };
            }
        });
        setButtons(updatedButtons);
    };
    const handleFloorNo =(index)=>{
        dispatch(floorNo(index));
    }
  return (
    <div className='row roomsAndBalconies floor_base'>
      <div className='col-lg-6' style={{paddingLeft:'0px'}}>
            {buttons.map((button, index) => (
                <button
                    key={index}
                    type="button"
                    className={button.selected ? 'selected_value' : 'unselected_btn'}
                    onClick={() => handleButtonClick(index,button.name)}
                >
                    {button.name}
                </button>
            ))}
        </div>
        <div className='col-lg-5' style={{paddingLeft:'0px'}}>
            {floorNos.map((floorNo, index) => (
                
            <button
                key={index}
                type="button"
                className={selectedFloorNo === index ? 'selected_value' : 'unselected_btn'}
                onClick={() => handleFloorNo(index)}
            >
                {floorNo}
            </button>
            ))}
        </div>
    </div>
  )
}

export default FloorNo