import React, { useState } from "react";
import logo from "../assets/img/logo.svg";
import './header.scss';
import home_icon from "../assets/img/icon/home_icon.svg";
import menu from "../assets/img/icon/menu.svg";
import SignUp from "./signUp";
import { useDispatch } from "react-redux";
import { signUpInTabs } from "../reducers/singnUpSlice";
const Header = () => {
  const [showSingUpPage, setSignUpPage] = useState(false);
  const [fromLogin, seFromLogin] = useState(false);
  const dispatch = useDispatch();
  const signUpAction =()=>{
    setSignUpPage(true);
  }

  const signInAction =()=>{
    dispatch(signUpInTabs('signin'))
    setSignUpPage(true);
    seFromLogin(true);
  }
  return (
    <div className="header">
      <nav class="navbar navbar-expand-lg navbar-light ">
        <div class="container">
          <a
            class="navbar-brand p-0"
            onClick={() => {
              window.location.href = "/";
            }}
          >
            <img src={logo} alt="logo" className="logo_img" />
          </a>
          <div
            class="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <img src={logo} />
          </div>
          <div class="menu-sec" id="navbarSupportedContent">
            {/* <div className="closebtn_sec">
              <div className="d-flex align-items-center justify-content-between">
                <img src={logo} alt="logo" className="logo" />
                <a href="javascript:void(0)" class="closebtn">
                  <img src={logo} alt="close" />
                </a>
              </div>
            </div> */}
            <ul class="navbar-nav ml-auto">
              <li class="nav-item">
                <button className="secondry_btn nav_btn" onClick={signUpAction}>
                   <div className="d-flex align-items-center">
                     <img src={home_icon} className="" alt="image"/>
                     Post Property
                   </div>
                </button>
              </li>
              <li class="nav-item">
              <button className="secondry_btn nav_btn" onClick={signInAction}>
                   <div className="d-flex align-items-center">
                     <img src={menu} className="" alt="image"/>
                     Login
                   </div>
                </button>
              </li>
              <li className="nav-item">
              <button className="primary_btn nav_btn">
              Talk to Us
                </button>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      {showSingUpPage?<SignUp showSignUp={showSingUpPage} closeSignUp={()=> setSignUpPage(false)} 
      fromLogin={fromLogin} />:''}
    </div>
  );
};
export default Header;
