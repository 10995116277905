import React from 'react'
import { Modal } from 'react-bootstrap'
import cloce_icon from "../assets/img/icon/close_icon.svg";
import ListBuy from './listBuy';
import './home.scss'
import FilterPropertyType from './filterPropertyType';
import RoomsAndBalconies from '../modules/properties/roomsAndBalconies'
import FloorNo from '../modules/properties/floorNo'
import TotalFloors from '../modules/properties/totalFloors'
import PropertyArea from '../modules/properties/propertyArea';
import TransactionType from '../modules/properties/transactionType';
import CarParkings from '../modules/properties/carParking'
const Filters = (props) => {
  return (
    <Modal show={props.showFilterModal} close={props.closeFilter}  dialogClassName="filter-popup-modal">
        <div className="modal-body profile_modal_body">
            <div className="flex_class align-items-center justify-content-between modal_common_header">
              <h5 className="modal-title" id="staticBackdropLabel">Filters</h5>
              <button className='no_pad_btn justify-content-end' style={{ border: 'none', background: 'none' }} onClick={(e) => { props.closeFilter() }}>
                <img src={cloce_icon} alt="image" className="close_icon" />
              </button>
            </div>
            <div className="filter-modal-scroll" id="scroll_style">
                <div className='row'>
                    <div className='col-lg-12'>
                        <ListBuy />
                        <FilterPropertyType />
                        <div className='row roomsAndBalcony'>
                            <div className='col-lg-1'></div>
                            <div className='col-lg-11'>
                                <RoomsAndBalconies />
                            </div>
                            
                        </div>
                        <div className='row floorno'>
                            <div className='col-lg-1'></div>
                            <div className='col-lg-11'>
                                <p className='common_color'>Floor No</p>
                                <FloorNo />
                            </div>
                        </div>
                        <div className='row total_floors'>
                            <div className='col-lg-1'></div>
                            <div className='col-lg-11'>
                                <TotalFloors />
                            </div>
                        </div>
                        <div className='row property_area'>
                            <div className='col-lg-1'></div>
                            <div className='col-lg-11'>
                                <p className='common_color'>Tell us your property area</p>
                                <PropertyArea />
                            </div>
                        </div>
                        <div className='row trans_type'>
                            <div className='col-lg-1'></div>
                            <div className='col-lg-11'>
                                <p className='common_color'>Tell us your property area</p>
                                <TransactionType />
                            </div>
                        </div>
                        <div className='row car_parkings'>
                            <div className='col-lg-1'></div>
                            <div className='col-lg-11'>
                                <CarParkings />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Modal.Footer>
            <div className='row'>
                <div className='col-lg-1'></div>
                <div className='col-lg-11'>
                    <div className='row d-flex'>
                        <div className='col-lg-9' style={{paddingLeft:'0px'}}>
                            <button className='danger_color' onClick={props.closeFilter}>Clear all filters and {" "} 
                            <button className='danger_color' style={{border:'none', background:'none',padding:'0px'}}> show all</button></button>
                        </div>
                        <div className='col-lg-2'>
                            <button className='apply_btn' onClick={props.closeFilter}>Apply</button>
                        </div>
                    </div>
                </div>
            </div>
        </Modal.Footer>
    </Modal>
  )
}

export default Filters