import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { ownerShipType } from '../../reducers/postPropertySlice';

const OwnerShipButtons = () => {
    const dispatch = useDispatch();
    const [buttons, setButtons] = useState([
        { name: 'Freehold', selected: false },
        { name: 'Power of Attorney', selected: false },
        { name: 'Leasehold', selected: false },
        { name: 'Co-Operative Society', selected: false }, ,
    ]);

    const handleButtonClick = (index, name) => {
        const updatedButtons = buttons.map((button, i) => {
            if (i === index) {
                dispatch(ownerShipType(name));
                return { ...button, selected: true };
            } else {
                return { ...button, selected: false };
            }
        });
        setButtons(updatedButtons);
    };

    return (
        <div>
            {buttons.map((button, index) => (
                <button
                    key={index}
                    type="button"
                    className={button.selected ? 'selected_value' : 'unselected_btn'}
                    onClick={() => handleButtonClick(index,button.name)}
                >
                    {button.name}
                </button>
            ))}
        </div>
    )
}

export default OwnerShipButtons