import React from "react";
import man from "../assets/img/common/man.svg";
const CallToAction = (props) =>{
    return(<div className="call_to_action">
        <div className="card pb-0">
          <h2 className="card_head mb-0 white_color">Looking for premium homes in your city? </h2>
          <p className="body_para white_color">ZlatoProperty is a premier real estate platform that provides comprehensive services to help buyers find their dream home.</p>
          <button className="primary_btn">{props.btn_text}</button>
           <div>
               <img src={man} alt="image" />
           </div>
        </div>
    </div>)
}
export default CallToAction;