import { data } from "jquery";
import React from "react";
const PaymentHistry = () => {
  const history_list = [
    {
      id: 1,
      name: "Silver Brick",
      plan: "60 Days | 2X Response Rate",
      status: "Paid",
      time: "Tue 2  May 1:28 AM",
      amount: "Rs. 35,000",
    },
    {
        id: 1,
        name: "Silver Brick",
        plan: "60 Days | 2X Response Rate",
        status: "Paid",
        time: "Tue 2  May 1:28 AM",
        amount: "Rs. 35,000",
      },
  ];
  return (
    <div className="history">
      <div className="" id="">
        {history_list.map((data, i) => {
          return (
            <div className="row d-flex align-items-center agent_dtl">
              <div className="col-lg-3 agent_name">
                <h1>{data.name}</h1>
                <p>{data.plan}</p>
              </div>
              <div className="col-lg-2 agent_bio">
                <h5>Status</h5>
                <p>{data.status}</p>
              </div>

              <div className="col-lg-3 agent_bio">
                <h5>Time</h5>
                <p>{data.time}</p>
              </div>
              <div className="col-lg-2 agent_bio">
                <h5>Amount</h5>
                <p>{data.amount}</p>
              </div>
              <div className="col-lg-2 agent_bio">
                downlooad
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default PaymentHistry;
