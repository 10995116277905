import React, { useState } from 'react'
import dining_table from '../../assets/img/icon/properties/features/dining-table 1.svg'
import gas from '../../assets/img/icon/properties/features/gas 1.svg'
import microwave from '../../assets/img/icon/properties/features/microwave-oven 1.svg'
import pot from '../../assets/img/icon/properties/features/pot 1.svg'
import refrigerator from '../../assets/img/icon/properties/features/refrigerator 1.svg'
import sofa from '../../assets/img/icon/properties/features/sofa 1.svg'
import washing_machine from '../../assets/img/icon/properties/features/washing-machine 1.svg'
import wifi_router from '../../assets/img/icon/properties/features/wifi-router 1.svg'
import './propertyType.scss';
const PropertyFeatures = () => {
    const features = [
        {
            id: 1,
            name: 'Sofa',
            src: sofa
        },
        {
            id: 2,
            name: 'Refrigerator',
            src: refrigerator
        },
        {
            id: 3,
            name: 'Dinning table',
            src: dining_table
        },
        {
            id: 4,
            name: 'Gas connection',
            src: gas
        },
        {
            id: 5,
            name: 'Washing machine',
            src: washing_machine
        },
        {
            id: 6,
            name: 'Wifi connection',
            src: wifi_router
        },
        {
            id: 7,
            name: 'Microwave',
            src: microwave
        },
        {
            id: 8,
            name: 'Other',
            src: pot
        },
    ]
    const [selectedImages, setSelectedImages] = useState([]);

    const handleImageClick = (id) => {
        if (selectedImages.includes(id)) {
            setSelectedImages(selectedImages.filter(item => item !== id));
          } else {
            setSelectedImages([...selectedImages, id]);
          }
    };
    console.log(selectedImages,"images")
    return (
        <div className="row image-grid features_func">
            {features.map((item) => {
                return (
                <div className='col-lg-2 featuers' style={{paddingLeft:'0px'}}>
                    <button style={{border:'none', background:'none'}}>
                        <div
                            key={item.name}
                            className={selectedImages.includes(item.id)? 'selected_property' : 'unselected_property'}
                            onClick={() => handleImageClick(item.id)}
                        >
                            <img src={item.src} alt={item.name} />
                        </div>
                    </button>
                    <div className="featuer_name">{item.name}</div>
                </div>
                )
            })}
            
        </div>
    )
}

export default PropertyFeatures