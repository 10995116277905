import React, { useState } from 'react'
import './home.scss'
const FilterPropertyType = () => {
    const images = [
        {
            id: 1,
            name: 'All',

        },
        {
            id: 2,
            name: 'Villas',
        },
        {
            id: 3,
            name: 'Apartments',
        },
        {
            id: 4,
            name: 'Plots',
        },
        {
            id: 5,
            name: '2BHK',
        },
        {
            id: 6,
            name: '3BHK',
        },
        {
            id: 7,
            name: '4BHK+',

        }
    ];
    const [selectedImageId, setSelectedImageId] = useState(null);
    const handleImageClick = (id, name) => {
        setSelectedImageId(id);
    };
    return (
        <div className="row image-selector">
            <div className='col-lg-1'></div>
            <div className='col-lg-8'>
                <p className='common_color'>Your Property type</p>
                <div className='flex_class'>
                    {images.map((image) => (
                        <div className='properties' style={{ marginRight: '20px' }}>
                            <button
                                key={image.id}
                                className={selectedImageId === image.id ? 'selected_filter' : 'unselected_filter'}
                                onClick={() => handleImageClick(image.id, image.name)}
                            >
                                <p>{image.name}</p>
                            </button>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default FilterPropertyType