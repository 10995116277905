import React from "react";
import t1 from "../assets/img/home/t1.svg";
const Testimonials = () => {
  const testimonials = [
    {
      id: 1,
      title: "Shanta Chhabra",
      subTitle: "Hyderabad",
      text: "I signed up on Magicbricks not knowing what to expect. Ive heard of other property websites but the interactions with their sales teams were not very good. However, on the contrary, the customer service team at Magicbricks was",
      img: t1,
    },
    {
      id: 1,
      title: "Shanta Chhabra",
      subTitle: "Hyderabad",
      text: "I signed up on Magicbricks not knowing what to expect. Ive heard of other property websites but the interactions with their sales teams were not very good. However, on the contrary, the customer service team at Magicbricks was",
      img: t1,
    },
  ];
  return (
    <div className="test_sec">
      <h2 className="section_head text-center">
        Testimonials from our customers
      </h2>
      <div className="row row_top">
        {testimonials.map((item, i) => {
          return (
            <div className="col-lg-6">
              <div className="card">
                <img src={item.img} alt="image" />
                <div className="titles">
                <h5 className="card_head white_color">{item.title}</h5>
                <h6 className="card_para">{item.subTitle}</h6>
                  </div>
                <p className="body_para white_color">{item.text}</p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default Testimonials;
