import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import {selectedBalconies, selectedRooms } from '../../reducers/postPropertySlice';
const RoomsAndBalconies = () => {
    const rooms =['1','2','3','4','5','6+'];
    const balconys=['1','2','3','4','5','6+'];
    const dispatch = useDispatch();
    const rooomsAndBalconey = useSelector((state) => state.postProperties);
    const roomsSelectedValue=rooomsAndBalconey?.selectedRooms;
    const balconySelectedValue= rooomsAndBalconey?.selectedBalconies;
    const handleRoomClick =(index)=>{
        dispatch(selectedRooms(index));
    }
    const handleBalconyClick=(index) =>{
        dispatch(selectedBalconies(index));
    }
  return (
    <div className='row roomsAndBalconies'>
        <div className='col-lg-5' style={{paddingLeft:'0px'}}>
            <p className='common_color'>Select Bedrooms<span style={{color:'#EE2F2F'}}>*</span></p>
            {rooms.map((room, index) => (
                <button
                    key={index}
                    type="button"
                    className={roomsSelectedValue === index ? 'selected_value' : 'unselected_btn'}
                    onClick={() => handleRoomClick(index)}
                >
                {room}
                </button>
            ))}
        </div>
        <div className='col-lg-5' style={{paddingLeft:'0px'}}>
            <p className='common_color'>Balconies<span style={{color:'#EE2F2F'}}>*</span></p>
            {balconys.map((balcony, index) => (
                
            <button
                key={index}
                type="button"
                className={balconySelectedValue === index ? 'selected_value' : 'unselected_btn'}
                onClick={() => handleBalconyClick(index)}
            >
                {balcony}
            </button>
            ))}
        </div>
    </div>
  )
}

export default RoomsAndBalconies