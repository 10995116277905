import React, { useState } from 'react'
import home_color_icon from "../../assets/img/icon/home_color_icon.svg";
import profile_icon from "../../assets/img/icon/profile_icon.svg";
import menu from "../../assets/img/icon/menu.svg";
import logo from "../../assets/img/logo.svg";
import "./navigation.scss"
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
const TopNavigation = () => {
    const pathsInfo = useSelector((state) => state.linkPathInfo);
    const linkValue = pathsInfo?.linkValue;
    const navigate = useNavigate();
    const navigatePostProperty =()=>{
        navigate('/postproperty');
    }
    return (
        <div className="header">
            <nav class="navbar navbar-expand-lg navbar-light ">
                <div class="container-fluid">
                   <div className='product_logo'>
                   <a
                        class="navbar-brand p-0"
                        onClick={() => {
                            window.location.href = "/";
                        }}
                    >
                        <img src={logo} alt="logo" />
                    </a>
                   </div>
               <div className='container pr-0'>
               <div className='d-flex justify-content-between align-items-center' style={{'width':'100%'}}>
                <div className="page_header">
                        <h2 className='header_names'>
                            {linkValue == 1 && 'Dashboard'}
                            {linkValue == 2 && "Enquiries"}
                            {linkValue == 3 && "Properties"}
                            {linkValue == 4 && "My Profile"}

                        </h2>
                    </div>
                    <div class="menu-sec" id="navbarSupportedContent">
                        <ul class="navbar-nav ml-auto">
                            <li class="nav-item">
                                <button className="primary_btn nav_btn mb-0" onClick={navigatePostProperty}>
                                    <div className="d-flex align-items-center">
                                        <img src={home_color_icon} className="" alt="image" />
                                        Post Property
                                    </div>
                                </button>
                            </li>
                            <li class="nav-item">
                                <button className="secondry_btn nav_btn mb-0">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <img src={menu} className="" alt="image" />
                                        <img src={profile_icon} className='mr-0' alt="profile" />
                                    </div>
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
               </div>
                </div>
            </nav>
        </div>
    )
}

export default TopNavigation