import React from "react";
import AdsHomeBanner from "../components/adsHomeBanner";
import BenefitsSec from "../components/benefitsSec";
import Footer from "../components/footer";
import Header from "../components/header";
import WorksSec from "../components/worksSec";
const AgentHomePage = () => {
  return (
    <div>
      <Header />
      <div className="allpage_banner">
          <div className="container">
          <section className="section_padding">
              <AdsHomeBanner />
            </section>
            <section className="section_padding pt-0">
              <BenefitsSec />
            </section>
            <section className="section_padding pt-0">
              <WorksSec fromAgentHome = {true} />
            </section>
          </div>
      </div>
      <Footer />
    </div>
  );
};
export default AgentHomePage;
