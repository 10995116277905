import React, { useState } from "react";
import MyPackage from "./my_package";
import MyProfile from "./my_profile";
import MyRatings from "./my_ratings";
import PaymentHistry from "./payment_history";
import Settings from "./settings";
import "./profile.scss";

const Profile = () => {
  const [selectedTab, setSelectedTab] = useState("profile");
  const tabTypes = [
    {
      id: 1,
      name: "Profile",
      to: "profile",
    },
    {
      id: 2,
      name: "My Package",
      to: "myPackage",
    },
    {
      id: 3,
      name: "Payment Histry",
      to: "paymentHistry",
    },
    {
      id: 4,
      name: "My Ratings",
      to: "myRatings",
    },
    {
      id: 5,
      name: "Settings",
      to: "settings",
    },
  ];
  const tabClickEvent = (tab) => {
    setSelectedTab(tab);
  };
  return (
    <div className="main_div_padding px-0">
      <div className="container">
        <div className="bloc-tab">
          <ul className="navs navs-tabs" id="myTab" role="tablist">
            {tabTypes.map((link) => {
              return (
                <li key={link.id} className="nav-item ">
                  <a
                    className={
                      "navs-link" + (selectedTab === link.to ? " active" : "")
                    }
                    href={"#" + link.to}
                    role="tab"
                    aria-controls="home"
                    data-bs-toggle="tab"
                    onClick={() => tabClickEvent(link.to)}
                  >
                    {link.name}
                  </a>
                </li>
              );
            })}
          </ul>
          <div className="tab-content p-0 top_builders search_main_div tab_padding">
            <div
              className="tab-pane active"
              id={selectedTab}
              role="tabpanel"
              aria-labelledby="home-tab"
            >
              {(() => {
                switch (selectedTab) {
                  case "profile":
                    return <MyProfile />;
                  case "myPackage":
                    return <MyPackage />;
                  case "paymentHistry":
                    return <PaymentHistry />;
                  case "myRatings":
                    return <MyRatings />;
                  case "settings":
                    return <Settings />;
                  default:
                    return null;
                }
              })()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
