import { createSlice } from "@reduxjs/toolkit";

export const navigationSlice = createSlice({
    name:"linkPathInfo",
    initialState:{
        linkPath:"",
        linkValue:""
    },
    reducers:{
        linkPath:(state, action)=>{
            state.linkPath = action.payload
        },
        linkValue:(state, action)=>{
            state.linkValue = action.payload
        }
    }
})
export const {linkPath, linkValue} = navigationSlice.actions;
export default navigationSlice.reducer;