import React, { useState } from 'react'
import './imageSection.scss';
import photo_icon from '../../assets/img/icon/properties/photo_icon.svg';
const ImageSection = () => {
    const [tabs, setTabs] = useState('exterior')
    const [selectedImage, setSelectedImage] = useState([]);
    const navlinks = [
        {
            id: 1,
            name: 'Cover Pic',
            to: 'coverpic'
        },
        {
            id: 2,
            name: 'Exterior View',
            to: 'exterior'
        },
        {
            id: 3,
            name: 'living Room',
            to: 'living'
        },
        {
            id: 4,
            name: 'Bedrooms',
            to: 'bedrooms'
        },
        {
            id: 5,
            name: 'Bathrooms',
            to: 'bathrooms'
        },
        {
            id: 6,
            name: 'Kitchen',
            to: 'kitchen'
        },
        {
            id: 7,
            name: 'Floor Plan',
            to: 'floor'
        },
        {
            id: 8,
            name: 'Master Plan',
            to: 'master'
        },
        {
            id: 9,
            name: 'Location Map',
            to: 'location'
        },
        {
            id: 10,
            name: 'Others',
            to: 'others'
        }
    ]
    const tabEvent = (tabValue) => {
        setTabs(tabValue);
    }
    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (!file) return;
        const imageUrl = URL.createObjectURL(file);
        setSelectedImage(prevImage => [...prevImage, imageUrl]);
    };
    console.log(selectedImage, "iamge")
    return (
        <div>
            <div className='row'>
                <div className="bloc-tab">
                    <ul
                        className="navs image-tabs"
                        id="myTab"
                        role="tablist"
                    >
                        {navlinks.map((link) => {
                            return (
                                <li key={link.id} className="nav-item img-tab">
                                    <a
                                        className={
                                            "img-link" + (
                                                tabs == link.to ? " active" : "")
                                        }
                                        href={"#" + link.to}
                                        role="tab"
                                        aria-controls="home"
                                        data-bs-toggle="tab"
                                        onClick={() => tabEvent(link.to)}
                                    >
                                        {link.name}
                                    </a>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </div>
            {selectedImage.length != 0 ?
                <div className="row d-flex preview-container">
                    {selectedImage.map(item => {
                        return (
                                <div className='col-lg-3 image_view'>
                                    <img src={item} alt="Preview" className="preview-image" />
                                </div>
                        )
                    })}
                    <div className="col-lg-2 adder-image text-center">
                        <div style={{ display: "flex", justifyContent: "center"}} className='image_view'>
                            <img src={photo_icon} alt="photo_icon" />
                        </div>
                        <input id="fileInput" type="file" accept="image/*,video/*"
                            style={{ opacity: 0, position: 'absolute' }}
                            onChange={handleImageChange} />
                            +Add
                    </div>
                </div>
                :
                <div className='justify-content-center align-items-center image_view add-image'>
                    <div className='row image_upload_div'>
                        <div className='col-lg-1'></div>
                        <div class="col-lg-10 card parent_div text-center">
                            <div class="card-body image_txt">
                                <p class="card-text image_header">
                                    85% of Buyers enquire on Properties with Photos
                                    Upload Photos & Get upto 10X more Enquiries
                                </p>
                                <button ><input id="fileInput" type="file" accept="image/*,video/*"
                                    style={{ opacity: 0, position: 'absolute' }}
                                    onChange={handleImageChange} />+Add Photos or video</button>
                            </div>
                        </div>
                        <div className='col-lg-1'></div>
                    </div>
                </div>
            }
        </div>

    )
}

export default ImageSection