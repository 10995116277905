import React from 'react'

const PropertyPrice = () => {
    return (
        <div className='row'>
            <div className='col-lg-5' style={{ paddingLeft: '0px' }}>
                <p className='common_color'>What is the expected price</p>
                <div class="input-group">
                    <input type="text" class="form-control" aria-label="Text input with dropdown button"
                        placeholder='Rs.' />
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">sq.feet</button>
                        <div class="dropdown-menu">
                            <a class="dropdown-item" href="#">Action</a>
                            <a class="dropdown-item" href="#">Another action</a>
                            <a class="dropdown-item" href="#">Something else here</a>
                        </div>
                    </div>
                </div>
                <small id="price_in_words" class="form-text text-muted">Price</small>
            </div>
            <div className='col-lg-5' style={{ paddingLeft: '0px' }}>
                <p className='common_color'>Booking/Token Amount(Optional)</p>
                <div class="input-group">
                    <input type="text" class="form-control" aria-label="Text input with dropdown button"
                        placeholder='Rs.' />
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">sq.feet</button>
                        <div class="dropdown-menu">
                            <a class="dropdown-item" href="#">Action</a>
                            <a class="dropdown-item" href="#">Another action</a>
                            <a class="dropdown-item" href="#">Something else here</a>
                        </div>
                    </div>
                </div>
                <small id="price_in_words" class="form-text text-muted">Price</small>
            </div>
        </div>
    )
}

export default PropertyPrice