import React from "react";
import agent_pic from "../assets/img/property1/agent_pic.png";
import star from "../assets/img/property1/star.png";
import call from "../assets/img/icon/call.svg";
import mail from "../assets/img/icon/mail.svg";
import map_icon from "../assets/img/icon/map_icon.svg";
import lang from "../assets/img/icon/lang.svg";
const AgentCard = () => {
  return (
    <div className="agent_card">
      <div className="card">
        <div className="row p-0">
          <div className="col-lg-2">
            <img src={agent_pic} className="agent_pic" />
            <button className="third_btn d-flex mx-auto">Certified</button>
          </div>
          <div className="col-lg-10">
            <div className="agent_card_body">
              <div className="row pt-0 align-items-center">
                <div className="col-lg-7">
                  <h2 className="one_property_head white_color">Chaten</h2>
                  <img src={star} className="stars" alt="image" />
                  <p className="body_para white_color">
                    GoldBreak values agents and their role in the real estate
                    industry. Our platform provides access to premium properties
                    and the support agents need to succeed. Join us and
                    experience the GoldBreak difference.
                  </p>
                </div>
                <div className="col-lg-5">
                  <div className="d-flex">
                    <div className="exp">
                      <p className="body_para white_color">Experience </p>
                      <h2 className="one_property_head white_color">Chaten</h2>
                    </div>
                    <div className="exp">
                      <p className="body_para white_color">
                        Listed Properties{" "}
                      </p>
                      <h2 className="one_property_head white_color">23</h2>
                    </div>
                  </div>
                </div>
              </div>
              <div className="dot_border"></div>
              <div className="row">
                <div className="col-lg-3">
                  <div className="d-flex details_div">
                    <img src={call} alt="image" />
                    <div>
                      <p className="body_para">Phone Number</p>
                      <p className="body_para">96XX XXX XXX</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="d-flex details_div">
                    <img src={mail} alt="image" />
                    <div>
                      <p className="body_para">Email ID</p>
                      <p className="body_para">xxxxxx.truly@gmail.com</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2">
                  <div className="d-flex details_div">
                    <img src={map_icon} alt="image" />
                    <div>
                      <p className="body_para">City </p>
                      <p className="body_para">Hyderabad</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="d-flex details_div">
                    <img src={lang} alt="image" />
                    <div>
                      <p className="body_para">Languages </p>
                      <p className="body_para">English,Telugu</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="dot_border"></div>
              <div className="d-flex btns_div">
                <button className="third_btn nav_btn">
                  Connect for partnership
                </button>
                <button className="outline_btn nav_btn">Write a Review</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AgentCard;
