import { createSlice } from "@reduxjs/toolkit";
import { act } from "react-dom/test-utils";

export const postPropertySlice = createSlice({
    name:'postProperties',
    initialState:{
        userType:'',
        ownerShipType:'',
        propertyType:'',
        facing:'',
        selectedRooms:null,
        selectedBalconies:null,
        floorType:'',
        floorNo:null,
        totalFloorNo:null,
        totalFloorType:'',
        featuresSelected:[],
        carParkingNo:null,
        transType:'',
        availability:''
    },
    reducers:{
        userType: (state,action) =>{
            state.userType=action.payload;
        },
        ownerShipType:(state, action) =>{
            state.ownerShipType = action.payload
        },
        propertyType:(state, action) =>{
            state.propertyType = action.payload;
        },
        facing:(state, action) =>{
            state.facing = action.payload
        },
        selectedRooms:(state, action) =>{
            state.selectedRooms = action.payload;
        },
        selectedBalconies:(state, action) =>{
            state.selectedBalconies = action.payload;
        },
        floorType:(state, action) =>{
            state.floorType = act.payload;
        },
        floorNo:(state,action) =>{
            state.floorNo = action.payload;
        },
        totalFloorNo:(state, action) =>{
            state.totalFloorNo = action.payload;
        },
        totalFloorType:(state,action)=>{
            state.totalFloorType = action.payload;
        },
        featuresSelected:(state, action) =>{
            state.featuresSelected = action.payload;
        },
        carParkingNo:(state, action) =>{
            state.carParkingNo = action.payload;
        },
        transType:(state, action) =>{
            state.transType = action.payload;
        },
        availability:(state, action) =>{
            state.availability = action.payload
        }
    }
})
export const {userType, ownerShipType, propertyType, facing, selectedRooms, selectedBalconies,
floorNo, floorType, totalFloorNo, totalFloorType, featuresSelected, carParkingNo, transType, availability} = postPropertySlice.actions;
export default postPropertySlice.reducer;