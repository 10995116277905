import { createSlice } from "@reduxjs/toolkit";

export const filtersInfoSlice = createSlice({
  name: "filtersInfo",
  initialState: {
    mainTabValue: "",
    childTabValue: "",
    singleProperty:null,
    mapSelectedTab:''
  },
  reducers: {
    mainTabValue: (state, action) => {
      state.mainTabValue = action.payload;
    },
    childTabValue: (state, action) => {
      state.childTabValue = action.payload;
    },
    singleProperty: (state, action) => {
      state.singleProperty = action.payload;
    },
    mapSelectedTab: (state, action) => {
      state.mapSelectedTab = action.payload;
    },
  },
});

export const { mainTabValue, childTabValue, singleProperty,mapSelectedTab } = filtersInfoSlice.actions;

export default filtersInfoSlice.reducer;
