import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import cloce_icon from "../assets/img/icon/close_icon.svg";
import OwlCarousel from "react-owl-carousel";
import img1 from "../assets/img/home/img1.png";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
const ImagesModal = (props) => {
  return (
    <Modal
      show={props.showImageModal}
      close={props.closeImageModal}
      className="images_modal"
    >
      <div className="container">
        <div className="modal_header d-flex justify-content-between align-items-center">
          <div>
            <h1 className="one_property_head">Sukhii Ubuntu</h1>
            <p className="body_para">
              Yelahanka, Hyderabad | Starting from 3.75* Crore | 3, 4 BR Villas
            </p>
          </div>
          <button
            style={{ border: "none", background: "none" }}
            onClick={(e) => {
              props.closeImageModal();
            }}
          >
            <img src={cloce_icon} alt="image" className="close_icon" />
          </button>
        </div>
        <div className="modal_body">
          <div className="row row_top">
            <div className="col-lg-12 p-0">
              <div className="">
                <OwlCarousel
                  items={1}
                  className="owl-theme popup_images"
                  margin={20}
                  loop
                  dots={true}
                  nav
                >
                  <div className="item">
                    <img src={img1} className="main_img" alt="image" />
                  </div>
                  <div className="item">
                    <img src={img1} className="main_img" alt="image" />
                  </div>
                  <div className="item">
                    <img src={img1} className="main_img" alt="image" />
                  </div>
                </OwlCarousel>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default ImagesModal;
