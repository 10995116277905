import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { facing } from '../../reducers/postPropertySlice';

const HouseFacing = () => {
    const dispatch = useDispatch();
    const [buttons, setButtons] = useState([
        { name: 'North', selected: false },
        { name: 'South', selected: false },
        { name: 'East', selected: false },
        { name: 'West', selected: false },
        { name: 'North East', selected: false },
        { name: 'North West', selected: false },
        { name: 'South East', selected: false },
        { name: 'South West', selected: false },
    ]);

    const handleButtonClick = (index, name) => {
        const updatedButtons = buttons.map((button, i) => {
            if (i === index) {
                dispatch(facing(name));
                return { ...button, selected: true };
            } else {
                return { ...button, selected: false };
            }
        });
        setButtons(updatedButtons);
    };
    return (
        <div className='housefacing'>
            {buttons.map((button, index) => (
                <button
                    key={index}
                    type="button"
                    className={button.selected ? 'selected_value' : 'unselected_btn'}
                    onClick={() => handleButtonClick(index, button.name)}
                >
                    {button.name}
                </button>
            ))}
        </div>
    )
}

export default HouseFacing