import React from "react";
import InputField from "./inputField";
const RequestDemo = () => {
  return (
    <div>
      <form className="request_demoform">
        <h2 className="card_head">Request a callback</h2>
        <div class="form-group">
          <InputField
            type="text"
            value=""
            label="Name"
            name="name"
            id="name"
            onChange={(e) => {
              // handleMobileNumber(e);
            }}
            starRequired={true}
          />
          <InputField
            type="text"
            value=""
            label="Mobile Number"
            name="mobilenumber"
            id="mobilenumber"
            onChange={(e) => {
              // handleMobileNumber(e);
            }}
            starRequired={true}
          />
          <InputField
            type="text"
            value=""
            label="Email ID"
            name="EmailID"
            id="EmailID"
            onChange={(e) => {
              // handleMobileNumber(e);
            }}
            starRequired={false}
          />
          <InputField
            type="text"
            value=""
            label="Message"
            name="message"
            id="message"
            onChange={(e) => {
              // handleMobileNumber(e);
            }}
            starRequired={false}
          />
          <div class="form-check">
            <input class="form-check-input" type="checkbox" id="gridCheck" />
            <label class="body_para" for="gridCheck">
            I Agree to GoldBreak T&C, Privacy Policy & Cookie Policy
            </label>
          </div>
          <button className="primary_btn">Submit</button>
        </div>
      </form>
    </div>
  );
};
export default RequestDemo;
