import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import ad_b1 from "../assets/img/home/ad_b1.png";
import "./adsBanner.scss";
import home_icon from "../assets/img/icon/home_icon.svg";
const AdsHomeBanner = () => {
  const ads_list = [
    {
      id: 1,
      title: "SellProperty On GoldBrick",
      text: "Take advantage of our targeted marketing strategies to connect with potential buyers.",
      cta1: "Post Property",
      cta2: "Sign Up",
      image: ad_b1,
    },
    {
        id: 2,
        title: "SellProperty On GoldBrick",
        text: "Take advantage of our targeted marketing strategies to connect with potential buyers.",
        cta1: "Post Property",
        cta2: "Sign Up",
        image: ad_b1,
      },
  ];
  const getBg = (img) => {
    var bg = {
      backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(0, 47, 16, 0.73)),
      url(${ad_b1})`,
    };
    return bg;
  };
  return (
    <div className="ads_banner">
      <OwlCarousel
        items={1}
        className="owl-theme property_car ads_car"
        margin={20}
        loop
        dots={true}
        nav
      >
        {ads_list.map((item, i) => {
          return (
            <div
              class="item ad_banner_item text-center"
              style={getBg(item.image)}
            >
              <div className="col-lg-6 text-left">
                <h2>{item.title}</h2>
                <p>{item.text}</p>
                <div className="d-flex btns_div">
                  <button className="secondry_btn nav_btn">
                    <div className="d-flex align-items-center ">
                      <img src={home_icon} className="icon" alt="image" />
                      {item.cta1}
                    </div>
                  </button>
                  <button className="third_btn nav_btn">{item.cta2}</button>
                </div>
              </div>
            </div>
          );
        })}
      </OwlCarousel>
    </div>
  );
};
export default AdsHomeBanner;
