import React from "react";
import home_icon from "../assets/img/icon/home_icon.svg";
import checklist from "../assets/img/icon/checklist.svg";
import handshake from "../assets/img/icon/handshake.svg";
const WorksSec = (props) => {
  const works_list = [
    {
      id: 1,
      name: "Post your Property Ad",
      icon: home_icon,
      text: "Enter all details like locality name, amenities etc. along with uploading Photos",
    },
    {
      id: 2,
      name: "Check Responses",
      icon: checklist,
      text: "Get access to Buyer/Tenant contact details & connect easily",
    },
    {
      id: 3,
      name: "Sell faster with Us",
      icon: handshake,
      text: "Negotiate with your prospective Buyer/Tenant & mutually close the deal.",
    },
  ];
  return (
    <div className="benefits_sec works_sec">
      <h2 className="section_head text-center">How it works</h2>
      <div className="row row_top">
        {works_list.map((item, i) => {
          return (
            <div className="col-lg-4">
              <div className="card">
                <img src={item.icon} className="" alt="image" />
                <h5 className="card_head">{item.name}</h5>
                <p className="body_para">{item.text}</p>
              </div>
            </div>
          );
        })}
      </div>
      {!props.fromAgentHome ? (
        <div className="d-flex btns_div row_top mx-auto justify-content-center">
          <button className="secondry_btn nav_btn mr-3">
            <div className="d-flex align-items-center ">
              <img src={home_icon} className="icon" alt="image" />
              Post Property
            </div>
          </button>
          <button className="third_btn nav_btn">Sign Up</button>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
export default WorksSec;
