import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { childTabValue, mainTabValue } from "../reducers/filtersInfoSlice";
import search_icon from "../assets/img/icon/search.svg";
import "./home.scss";
import TopBuildersSec from "../components/topBuildersSec";
import BenefitsSec from "../components/benefitsSec";
import WorksSec from "../components/worksSec";
import AdsHomeBanner from "../components/adsHomeBanner";
import Testimonials from "../components/testimonials";
import Footer from "../components/footer";
import Header from "../components/header";
import { useNavigate } from "react-router-dom";
import Filters from "./filters";
import { getHealthCheck } from "../actions/commonService";
const Home = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  var filtersData = useSelector((state) => state.filtersInfo);
  const selectedMainTab = filtersData?.mainTabValue;
  const selectedChildTab = filtersData?.childTabValue;
  const[showFilters, setShowFilters] = useState(false);
  useEffect(() => {
    dispatch(mainTabValue("residential"));
    dispatch(childTabValue("all"));
    var hi = getHealthCheck();
    console.log(hi,'healthcheck')
  }, []);
  const mainTabLinks = [
    {
      id: 1,
      name: "Residential",
      to: "residential",
    },
    {
      id: 2,
      name: "Commercial",
      to: "commercial",
    },
  ];
  const childTabLinks = [
    {
      id: 1,
      name: "All",
      to: "all",
    },
    {
      id: 2,
      name: "Villas",
      to: "villas",
    },
    {
      id: 3,
      name: "Apartments",
      to: "apartments",
    },
    {
      id: 4,
      name: "Plots",
      to: "plots",
    },
    {
      id: 5,
      name: "2 BHK",
      to: "2BHK",
    },
    {
      id: 6,
      name: "3 BHK",
      to: "3BHK",
    },
    {
      id: 7,
      name: "4 BHK+",
      to: "4BHK",
    },
    {
      id:8,
      name:'Filters',
      to:'filters'
    }
  ];
  const mainTabChange = (tab) => {
    dispatch(mainTabValue(tab));
  };
  const childTabChange = (tab) => {
    if(tab == 'filters'){
      setShowFilters(true);
    }
    dispatch(childTabValue(tab));
  };
  const list1 = [
    {
      name: "Property for Sale in Hyderabad",
    },
    {
      name: "Flats in Hyderabad",
    },
    {
      name: "Apartments in Hyderabad",
    },
    {
      name: "Studio Apartments in Hyderabad",
    },
    {
      name: "Resale House in Hyderabad",
    },
    {
      name: "Plots for Sale in Hyderabad",
    },
    {
      name: " House for Sale in Hyderabad",
    },
    {
      name: "Villas for Sale in Hyderabad",
    },
    {
      name: "Penthouse in Hyderabad",
    },
    {
      name: "Ready To Move Flats in Hyderabad",
    },
    {
      name: "Resale Flats in Hyderabad",
    },
  ];
  const list2 = [
    {
      name: "1 BHK Flats in Hyderabad",
    },
    {
      name: "2 BHK Flats in Hyderabad",
    },
    {
      name: "3 BHK Flats in Hyderabad",
    },
    {
      name: "4 BHK Flats in Hyderabad",
    },
    {
      name: "1 BHK House for Sale in Hyderabad",
    },
    {
      name: "2 BHK House for Sale in Hyderabad",
    },
    {
      name: "3 BHK House for Sale in Hyderabad",
    },
    {
      name: "4 BHK House for Sale in Hyderabad",
    },
    {
      name: "1 BHK Villa for Sale in Hyderabad",
    },
    {
      name: "2 BHK Villa for Sale in Hyderabad",
    },
    {
      name: "3 BHK Villa for Sale in Hyderabad",
    },
    {
      name: "4 BHK Villa for Sale in Hyderabad",
    },
  ];
  const list3 = [
    {
      name: "Flats for Sale in Kondapur Hyderabad",
    },
    {
      name: "Flats for Sale in Gachibowli Hyderabad",
    },
    {
      name: "Flats for Sale in Manikonda Hyderabad",
    },
    {
      name: "Flats for Sale in Miyapur Hyderabad",
    },
    {
      name: "Flats for Sale in Kompally Hyderabad",
    },
    {
      name: "Flats for Sale in Kukatpally Hyderabad",
    },
    {
      name: "Flats for Sale in Kokapet Hyderabad",
    },
    {
      name: "Flats for Sale in Tellapur Hyderabad",
    },
    {
      name: "Flats for Sale in Bachupally Hyderabad",
    },
    {
      name: "Flats for Sale in Narsingi Hyderabad",
    },
  ];
  const searchProject = () => {
    navigate("/search-properties");
  };
  return (
    <div>
      <Header />
      <div className="allpage_banner">
        <div className="main_banner">
          <div className="container">
            <div className="banner">
              <div className="banner_div">
                <h1>Looking for premium homes in your city? </h1>
                <div className="filters_sec pb-0">
                  <div className="want_buy_text">
                    <p>i want to buy</p>
                  </div>
                  <div>
                    <ul
                      className="nav nav-tabs home_main_tabs"
                      id="myTab"
                      role="tablist"
                    >
                      {mainTabLinks.map((mainTab) => {
                        return (
                          <li key={mainTab.id} className="nav-item">
                            <a
                              className={
                                "nav-link" +
                                (selectedMainTab == mainTab.to ? " active" : "")
                              }
                              href={"#" + selectedMainTab}
                              role="tab"
                              aria-controls="home"
                              data-bs-toggle="tab"
                              onClick={() => mainTabChange(mainTab.to)}
                            >
                              {mainTab.name}
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                    <div className="tab-content tab_padding">
                      <div
                        className="tab-pane active"
                        id={selectedMainTab}
                        role="tabpanel"
                        aria-labelledby="home-tab"
                      >
                        <div>
                          <ul
                            className="nav nav-tabs home_child_tabs"
                            id="myTab"
                            role="tablist"
                          >
                            {childTabLinks.map((childTab) => {
                              return (
                                <li key={childTab.id} className="nav-item">
                                  <a
                                    className={
                                      "nav-link" +
                                      (selectedChildTab == childTab.to
                                        ? " active"
                                        : "")
                                    }
                                    href={"#" + selectedChildTab}
                                    role="tab"
                                    aria-controls="home"
                                    data-bs-toggle="tab"
                                    onClick={() => childTabChange(childTab.to)}
                                  >
                                    {childTab.name}
                                  </a>
                                </li>
                              );
                            })}
                          </ul>
                          <div className="tab-content tab_padding">
                            <div
                              className="tab-pane active"
                              id={selectedChildTab}
                              role="tabpanel"
                              aria-labelledby="home-tab"
                            >
                              <div className="input-group align-items-center">
                                <div class="input-group-prepend">
                                  <img src={search_icon} alt="icon" />
                                </div>
                                <input type="text" className="form-control" />
                                <button
                                  className="primary_btn"
                                  onClick={() => {
                                    searchProject();
                                  }}
                                >
                                  Go
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <section className="section_padding">
              <TopBuildersSec type="Top Builders" />
            </section>
            <section className="section_padding pt-0">
              <BenefitsSec />
            </section>
            <section className="section_padding pt-0">
              <TopBuildersSec type="Top Builders in Hyderabad" />
            </section>
            <section className="section_padding pt-0">
              <AdsHomeBanner />
            </section>
            <section className="section_padding pt-0">
              <WorksSec />
            </section>
          </div>
          <section className="Testimonial section_padding">
            <div className="container">
              <Testimonials />
            </div>
          </section>
          <div className="container">
            <section className="section_padding">
              <AdsHomeBanner />
            </section>
          </div>
          <div className="container pt-0">
            <section className="allproperty section_padding">
              <h2 className="section_head text-center">
                Property Options in Hyderabad
              </h2>
              <div className="allproperty_card row_top">
                <div className="row">
                  <div className="col-lg-4">
                    <h3 className="card_head">Popular Residential Searches</h3>
                  </div>
                  <div className="col-lg-4">
                    <h3 className="card_head">Popular BHK Searches</h3>
                  </div>
                  <div className="col-lg-4">
                    <h3 className="card_head">Popular Flat Searches</h3>
                  </div>
                </div>
                <div className="row allproperty_card_body" id="scroll_style">
                  <div className="col-lg-4">
                    <ul>
                      {list1.map((item, i) => {
                        return <li>{item.name}</li>;
                      })}
                    </ul>
                  </div>
                  <div className="col-lg-4">
                    <ul id="">
                      {list2.map((item, i) => {
                        return <li>{item.name}</li>;
                      })}
                    </ul>
                  </div>
                  <div className="col-lg-4">
                    <ul id="">
                      {list3.map((item, i) => {
                        return <li>{item.name}</li>;
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            </section>
          </div>
          {/* footerr */}
          <Footer />
          <div className="container">
            <div className="disclaimer_sec">
              <h4 className="card_head">Disclaimer</h4>
              <p className="body_para">
                GoldBreak is a real estate platform that connects buyers with
                top builders offering premium homes in sought-after locations.
                We act only as an intermediary and provide a platform to
                advertise properties of sellers for potential buyers. We are not
                a party to, privy to, or control in any manner any transactions
                between sellers and buyers.
              </p>
              <p className="body_para">
                All the offers and discounts advertised on our platform have
                been extended by various builders and developers, and we simply
                communicate these offers without selling or rendering any of the
                products or services ourselves. We do not provide any warranties
                or make any representations with respect to the offers made on
                our site.
              </p>
              <p className="body_para mb-0">
                GoldBreak shall not be responsible or liable to mediate or
                resolve any disputes or disagreements between buyers and
                sellers, and both parties shall settle all such disputes without
                involving GoldBreak in any manner. We are committed to providing
                a transparent and reliable platform that connects buyers and
                sellers, and we encourage both parties to engage in fair and
                honest transactions.
              </p>
            </div>
          </div>
        </div>
      </div>
      {showFilters?
      <Filters showFilterModal={showFilters} closeFilter={() => setShowFilters(false)} />:''}
    </div>
  );
};
export default Home;
