import React, { useState } from "react";
import user_pic from "../../assets/img/icon/user_pic.svg";
import call from "../../assets/img/icon/call.svg";
import ProfileModalPopUp from "./profileModalPopUp";
const MyProfile = () => {
  const [showProfileModal, setShowProfileModal] = useState(false);
  const openModalPopUp =()=>{
    setShowProfileModal(true);
  }
  return (
    <div className="my_profile_div">
      <div className="row">
        <div className="col-lg-7 pl-0">
          <div className="personal_details">
            <div className="card">
              <div className="d-flex align-items-start justify-content-between">
                <div>
                  <h1 className="header-tag mb-0">Hello !</h1>
                  <p className="body_para">Good to see you </p>
                </div>
                <div>
                  <img src={user_pic} alt="image" />
                </div>
              </div>
              <div className="">
                <h2 className="person_name">Sreedhar Setti</h2>
                <p className="body_para">
                  GoldBreak values agents and their role in the real estate
                  industry. Our platform provides access to premium properties
                  and the support agents need to succeed. Join us and experience
                  the GoldBreak difference.
                </p>
              </div>
              <div className="row personal_details_row row_top">
                <div className="col-lg-4 pl-0">
                  <div className="d-flex details_div">
                    <img src={call} alt="image" />
                    <div>
                      <p className="body_para">Phone Number</p>
                      <p className="body_para">96XX XXX XXX</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="d-flex details_div">
                    <img src={call} alt="image" />
                    <div>
                      <p className="body_para">Email ID</p>
                      <p className="body_para">sreedhar.truly.com</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row personal_details_row row_top">
                <div className="col-lg-4 pl-0">
                  <div className="d-flex details_div">
                    <img src={call} alt="image" />
                    <div>
                      <p className="body_para">Phone Number</p>
                      <p className="body_para">96XX XXX XXX</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="d-flex details_div">
                    <img src={call} alt="image" />
                    <div>
                      <p className="body_para">Email ID</p>
                      <p className="body_para">sreedhar.truly.com</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row personal_details_row row_top">
                <div className="col-lg-4 pl-0">
                  <div className="d-flex details_div">
                    <img src={call} alt="image" />
                    <div>
                      <p className="body_para">Experiences</p>
                      <p className="body_para">20 Years</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="d-flex details_div">
                    <img src={call} alt="image" />
                    <div>
                      <p className="body_para">City</p>
                      <p className="body_para">Hyderabad</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row personal_details_row row_top">
                <div className="col-lg-4 pl-0">
                  <div className="d-flex details_div">
                    <img src={call} alt="image" />
                    <div>
                      <p className="body_para">Languages</p>
                      <p className="body_para">English,Telugu</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row justify-content-end">
                <button className="secondry_btn" onClick={openModalPopUp}>Edit Info</button>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-5 left_div">
            <div className="card rera_card">
               <h3 className="person_name">RERA Registration Details</h3>
               <p className="body_para">The RERA details will be visible to prospective buyers on your properties and profile</p>
               <button className="primary_btn nav_btn">+ Add RERA Details</button>
            </div>
            <div className="card">
               <h3 className="person_name">Get certified tag account</h3>
               <p className="body_para">This Will help you to get more leads</p>
               <button className="primary_btn nav_btn">Apply Now</button>
            </div>
            <div className="card">
               <h3 className="person_name">Verify your account</h3>
               <p className="body_para">Aadhar card verification call-to-action (CTA) for you.</p>
               <button className="primary_btn nav_btn">+ Verify Now</button>
            </div>
        </div>
      </div>
      {showProfileModal?<ProfileModalPopUp showModal={showProfileModal} closeModal={()=> setShowProfileModal(false)} />:''}
    </div>
  );
};
export default MyProfile;
