import React from "react";
import tick from "../../assets/img/icon/tick.svg";
const MyPackage = () => {
  const packages_list = [
    {
      id: 1,
      plant_title: "Free Trial",
      plan_days: "60 Days | 2X Response Rate",
      price: "Rs. 0",
      para: "Number of listings 1- 5",
      payStatus: true,
    },
    {
      id: 1,
      plant_title: "Silver Brick",
      plan_days: "60 Days | 2X Response Rate",
      price: "Rs. 35,000",
      para: "Number of listings 1- 5",
      payStatus: false,
    },
  ];
  return (
    <div className="package_sec">
      <div className="row">
        {packages_list.map((item, i) => {
          return (
            <div className="col-lg-4 pl-0">
              <div className="card">
                <h1 className="person_name mb-0">{item.plant_title}</h1>
                <h4 className="body_para plan_days">{item.plan_days}</h4>
                <h3 className="person_name price">{item.price}</h3>
                <div className="plan_ben">
                  <div className="d-flex">
                    <img src={tick} alt="image" />
                    <p className="body_para">{item.para}</p>
                  </div>
                  <div className="d-flex">
                    <img src={tick} alt="image" />
                    <p className="body_para">{item.para}</p>
                  </div>
                </div>
                {item.payStatus ? (
                  <div>
                    <h3 className="person_name price">{"Current Plan"}</h3>
                    <p className="body_para">48 Days Left</p>
                  </div>
                ) : (
                  <div className="buy_btn">
                    <button className="primary_btn d-flex mr-auto">
                      Buy Now
                    </button>
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
      <div className="left_div">
        <div className="card rera_card">
          <div className="row">
            <div className="col-lg-8">
              <h3 className="person_name">Special Brick</h3>
              <p className="body_para">
                If you're interested in a package tailored specifically to your
                needs, our sales team is here to help. Contact us today to
                discuss your requirements and discover how GoldBreak can help
                take your real estate business to the next level.
              </p>
            </div>
            <div className="col-lg-4">
              <button className="primary_btn nav_btn">Contact Sales</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default MyPackage;
