import React from 'react'
import { Modal } from "react-bootstrap";
import cloce_icon from "../../assets/img/icon/close_icon.svg"
const MapModal = (props) => {
    const closePopUp = (e) => {
        // dispatch(signUpInTabs('signup'));
        props.closeMap();
        e.preventDefault();
    }
    return (
        <Modal show={props.showMap} close={props.closeMap} dialogClassName="signup-popup-modal">
            <div className="modal-body singup-body">
                <div className="d-flex align-items-center justify-content-between modal_common_header">
                    <h5 className="modal-title locality_title" id="staticBackdropLabel">
                        Choose Your Locality/Project
                    </h5>
                    <button style={{ border: 'none', background: 'none' }}
                        onClick={(e) => {
                            closePopUp(e)
                        }}
                    >
                        <img src={cloce_icon} alt="image" className="close_icon" />
                    </button>
                </div>
                <div className="signup-modal-scroll" id="scroll_style">
                    <div className='row map_modal'>
                        <div className='col-lg-1'></div>
                        <div className='col-lg-4'>
                            <div class="form-group input_tabs">
                                <label for="formGroupExampleInput" className='labels'>City*</label>
                                <input type="text" class="form-control" id="city" />
                            </div>
                            <div class="form-group input_tabs">
                                <label for="formGroupExampleInput" className='labels'>Building/Project/Society*</label>
                                <input type="text" class="form-control" id="project" />
                            </div>
                            <div class="form-group input_tabs">
                                <label for="formGroupExampleInput" className='labels'>Locality*</label>
                                <input type="text" class="form-control" id="locality" />
                            </div>
                        </div>
                        <div className='col-lg-6'>Map</div>
                        <div className='col-lg-1'>Space</div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default MapModal