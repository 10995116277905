import React, { useState } from 'react'
import home_color_icon from "../../assets/img/icon/home_color_icon.svg";
import no_data_available from "../../assets/img/icon/enquiries/no_data_available.svg";
import '../enquirie/enquiry.scss'
import { useNavigate } from 'react-router';
import property_img from '../../assets/img/icon/properties/property_img.png'
import delete_icon from '../../assets/img/icon/properties/delete_icon.svg'
import edit_icon from '../../assets/img/icon/properties/edit_icon.svg'
import curve_icon from '../../assets/img/icon/properties/curve_icon.svg';
import ubuntu_icon from '../../assets/img/icon/properties/ubuntu_icon.svg';
import DeleteModalPopUp from './deleteModalPopUp';
const Property = () => {
    const navigate = useNavigate();
    const [showDelModal, setShowDelModal] = useState(false);
    const [tabs, setTabs] = useState('all');
    const properties = [
        {
            id: 1,
            image: '',
            agentName: 'Pavan',
            location: 'Benguluru',
            type: '3.4 BR Villas',
            cost: 3.75,
        },
        {
            id: 2,
            image: '',
            agentName: 'Venu',
            location: 'Hydrabad',
            type: '4.4 BR Villas',
            cost: 4.75,
        },
        {
            id: 3,
            image: '',
            agentName: 'Venu',
            location: 'Hydrabad',
            type: '4.4 BR Villas',
            cost: 4.75,
        },
        {
            id: 4,
            image: '',
            agentName: 'Venu',
            location: 'Hydrabad',
            type: '4.4 BR Villas',
            cost: 4.75,
        }
    ]
    const childTabLinks = [
        {
            id: 1,
            name: "All",
            to: "all",
        },
        {
            id: 2,
            name: "Villas",
            to: "villas",
        },
        {
            id: 3,
            name: "Apartments",
            to: "apartments",
        },
        {
            id: 4,
            name: "Plots",
            to: "plots",
        },
        {
            id: 5,
            name: "2 BHK",
            to: "2BHK",
        },
        {
            id: 6,
            name: "3 BHK",
            to: "3BHK",
        },
        {
            id: 7,
            name: "4 BHK+",
            to: "4BHK",
        },
    ];
    const tabEvent = (tabValue) => {
        setTabs(tabValue);
    }

    const routeToPostProperty = () => {
        navigate('/postproperty')
    }
    const removeProperty =()=>{
        setShowDelModal(true);
    }
    return (
        <div className="main_div_padding">
            {properties.length != 0 ?
                <div className='row '>
                    <div className='col-lg-1'></div>
                    <div className='col-lg-10 enquery_details'>
                        <div>
                            <div className="bloc-tab">
                                <ul
                                    className="navs navs-tabs"
                                    id="myTab"
                                    role="tablist"
                                >
                                    {childTabLinks.map((link) => {
                                        return (
                                            <li key={link.id} className="nav-item ">
                                                <a
                                                    className={
                                                        "navs-link" + (
                                                            tabs == link.to ? " active" : "")
                                                    }
                                                    href={"#" + link.to}
                                                    role="tab"
                                                    aria-controls="home"
                                                    data-bs-toggle="tab"
                                                    onClick={() => tabEvent(link.to)}
                                                >
                                                    {link.name}
                                                </a>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                            <div className='row property_view' id="scroll_style">
                                {properties.map(item => {
                                    return (
                                        <div className='col-lg-4 properties_card' style={{ paddingLeft: '0px' }}>
                                            <div class="card property_dtl_card">
                                                <div class="img-container" style={{ position: 'relative' }}>
                                                    <img class="card-img-top" src={property_img} alt="Card image cap" />
                                                    <div className='ubuntu_logo'>
                                                        <img src={ubuntu_icon} alt='ubuntu_icon' />
                                                    </div>
                                                    <div className='curve_icon'>
                                                    <img src={curve_icon} alt ="curve"/>
                                                    </div>
                                                </div>
                                                <div class="card-body property_body">
                                                    <h5 class="card-title">{item.agentName}</h5>
                                                    <p class="card-text project_location">{item.location}</p>
                                                    <p class="card-text">{item.type}</p>
                                                    <p class="card-text cost">Starts from {item.cost}</p>
                                                    <div className='flex_class'>
                                                        <div className='del_icon mr-2'>
                                                            <button type='button' style={{border:'none', background:'none'}}
                                                                onClick={removeProperty}>
                                                                <img src={delete_icon} alt="delete_icon" />
                                                            </button>
                                                        </div>
                                                        <div className='edit_info'>
                                                            <button type="button" className='edit_btn'>
                                                                <div className='edit_icon_info'>
                                                                    <img src={edit_icon} alt="edit_icon" />
                                                                    <p>Edit Info</p>
                                                                </div>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-1'></div>
                </div>
                :
                <div>
                    <div className='row'>
                        <div className='col-lg-1'></div>
                        <div className='col-lg-9'>
                            <div className='welcome_card'>
                                <div className='d-flex justify-content-between wel_gold'>
                                    <div>
                                        <h1 className='header-tag'>No Properties!</h1>
                                        <p>No Data Available.</p>
                                    </div>
                                    <div>
                                        <img src={no_data_available} alt="no _data" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-2'></div>
                    </div>
                    <div className='row'>
                        <div className='col-lg-1'></div>
                        <div className='col-lg-9'>
                            <div className='property_card'>
                                <div className='wel_gold'>
                                    <h1 className='header-tag'>Sell Property!</h1>
                                    <div className='d-flex justify-content-between'>
                                        <div>
                                            <p>Our platform is designed to help builders like you showcase your</p>
                                            <p> properties to a wider audience and connect with potential buyers who</p>
                                            <p> are interested in premium homes in sought-after locations.</p>
                                        </div>
                                        <button className="primary_btn nav_btn" onClick={routeToPostProperty}>
                                            <div className="d-flex align-items-center">
                                                <img src={home_color_icon} className="" alt="image" />
                                                Post Property
                                            </div>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-2'></div>
                    </div>
                </div>
            }
            {showDelModal?<DeleteModalPopUp showDelModal={showDelModal} closeDelModal={() =>setShowDelModal(false)} />:''}
        </div>
    )
}

export default Property