import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { propertyType } from '../../reducers/postPropertySlice';
import house_icon from '../../assets/img/icon/properties/hose_icon.svg';
import apartments from '../../assets/img/icon/properties/apartments_icon.svg';
import builder_icon from '../../assets/img/icon/properties/builder_icon.svg';
import indhouse_icon from '../../assets/img/icon/properties/indhouse_icon.svg';
import penthouse_icon from '../../assets/img/icon/properties/penthouse_icon.svg';
import location_icon from '../../assets/img/icon/properties/location_icon.svg';
import studio_icon from '../../assets/img/icon/properties/studio_icon.svg';
import other_icon from '../../assets/img/icon/properties/other_icon.svg';
import './propertyType.scss';
const PropertyType = () => {
    const images =[
        {
            id:1,
            name:'Villas',
            src:house_icon,
        },
        {
            id:2,
            name:'Apartments',
            src:apartments,
        },
        {
            id:3,
            name:'Independent builder floor',
            src:builder_icon,
        },
        {
            id:4,
            name:'Independent house',
            src:indhouse_icon,
        },
        {
            id:5,
            name:'Penthouse',
            src:penthouse_icon,
        },
        {
            id:6,
            name:'Plot',
            src:location_icon,
        },
        {
            id:7,
            name:'Studio',
            src:studio_icon,
        },
        {
            id:8,
            name:'Other',
            src:other_icon,
        }
    ]
    const dispatch = useDispatch();
    const [selectedImageId, setSelectedImageId] = useState(null);

    const handleImageClick = (id,name) => {
        dispatch(propertyType(name));
        setSelectedImageId(id);
    };
  return (
    <div className="row image-selector">
      {images.map((image) => (
        <div className='col-lg-2 properties' style={{paddingLeft:'0px'}}>
            <button
            key={image.id}
            className={`image-wrapper ${selectedImageId === image.id ? 'selected_property' : 'unselected_property'}`}
            onClick={() => handleImageClick(image.id, image.name)}
            >
            <img src={image.src} alt={image.name} />
            </button>
            <div className="image-name">{image.name}</div>
        </div>
      ))}
    </div>
  )
}

export default PropertyType