import React from "react";
import "./topBuilders.scss";
import img1 from "../assets/img/home/img1.png";
import propertyLogo from "../assets/img/home/property_logo.svg";
import next_icon from "../assets/img/icon/next_icon.svg";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
const TopBuildersSec = (props) => {
  const list = [
    {
      id: 1,
      img: img1,
      name: "Riverscape",
      address: "Bandlaguda, TSPA Junction",
      propertyType: "3, 4 BR Villas",
      price: "Starting from ₹4* Crores",
      logo: propertyLogo,
      boostAd: false,
    },
    {
      id: 2,
      img: img1,
      name: "Riverscape",
      address: "Bandlaguda, TSPA Junction",
      propertyType: "3, 4 BR Villas",
      price: "Starting from ₹4* Crores",
      logo: propertyLogo,
      boostAd: false,
    },
    {
      id: 3,
      img: img1,
      name: "Riverscape",
      address: "Bandlaguda, TSPA Junction",
      propertyType: "3, 4 BR Villas",
      price: "Starting from ₹4* Crores",
      logo: propertyLogo,
      boostAd: true,
    },
    {
      id: 4,
      img: img1,
      name: "Riverscape",
      address: "Bandlaguda, TSPA Junction",
      propertyType: "3, 4 BR Villas",
      price: "Starting from ₹4* Crores",
      logo: propertyLogo,
      boostAd: true,
    },
  ];
  return (
    <div className="top_builders search_main_div">
      <h2 className="section_head text-center">{props.type}</h2>
      <div className="row row_top">
        {list.map((item, i) => {
          return (
            <div className="col-lg-3">
              <div className="card">
                <div className="images_sec">
                  <OwlCarousel
                    items={1}
                    className="owl-theme property_car"
                    margin={20}
                    loop
                    dots={true}
                    nav
                  >
                    <div className="item">
                      <img src={item.img} className="main_img" alt="image" />
                    </div>
                    <div className="item">
                      <img src={item.img} className="main_img" alt="image" />
                    </div>
                    <div className="item">
                      <img src={item.img} className="main_img" alt="image" />
                    </div>
                  </OwlCarousel>

                  <div className="logo_image">
                    <img src={item.logo} alt="image" />
                  </div>
                </div>

                <div className="card_body">
                  <h5 className="card_head">{item.name}</h5>
                  <p className="grey_para">{item.address}</p>
                  <div className="d-flex justify-content-between price_sec">
                    <div>
                      <h5 className="grey_para">{item.propertyType}</h5>
                      <p className="body_para bold_para">{item.price}</p>
                    </div>
                    <img src={next_icon} />
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default TopBuildersSec;
