import React from 'react'
import { Modal } from 'react-bootstrap'
import './deleteModalPopUp.scss'
import close_icon from "../../assets/img/icon/close_icon.svg"
const DeleteModalPopUp = (props) => {
    return (
        <Modal show={props.showDelModal} close={props.closeDelModal} dialogClassName="delete-property-popup-modal">
            <div className="modal-body delete_property_body">
                <div className="d-flex modal_common_header justify-content-end">
                    <button
                        style={{ border: 'none', background: 'none' }}
                        onClick={(e) => {
                            props.closeDelModal();
                            e.preventDefault();
                        }}
                    >
                        <img src={close_icon} alt="image" className="close_icon"/>
                    </button>
                </div>
                <div className="remove_property">
                    <div className='row justify-content-center header_tag'>
                            <p>Are you sure you want to remove the property</p>
                    </div>
                    <div className='row'>
                        <div className='col-lg-2'></div>
                        <div className='col-lg-8 text_value'>
                            <div className='paragraph_text'>
                                <p>The RERA details will be visible to prospective buyers on your properties and profile</p>
                            </div>
                            <div className='flex_class buttons'>
                                <button type='button' onClick={props.closeDelModal}>
                                    <p style={{color: '#002F10'}}>No</p>
                                </button>
                                <button type='button' className='yes_btn' onClick={props.closeDelModal}>
                                    <p style={{color:'#D49941'}}>Yes</p>
                                </button>
                        </div>
                        </div>
                        <div className='col-lg-2'></div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default DeleteModalPopUp