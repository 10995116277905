import React, { useState } from "react";
import t1 from "../../assets/img/home/t1.svg";

const MyRatings = () => {
  const [ratingsList, setRatingsList] = useState([
    {
      id: 1,
      img: t1,
      name: "Shanta Chhabra",
      title: "Hyderabad",
      para:
        "I signed up on Magicbricks not knowing what to expect. Ive heard of other property websites but the interactions with their sales teams were not very good. However, on the contrary, the customer service team at Magicbricks was",
      status: false,
    },
    {
      id: 2,
      img: t1,
      name: "Shanta Chhabra",
      title: "Hyderabad",
      para:
        "I signed up on Magicbricks not knowing what to expect. Ive heard of other property websites but the interactions with their sales teams were not very good. However, on the contrary, the customer service team at Magicbricks was",
      status: true,
    },
  ]);

  const toggleStatus = (id) => {
    const updatedList = ratingsList.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          status: !item.status,
        };
      }
      return item;
    });
    setRatingsList(updatedList);
  };

  return (
    <div className="ratings">
      <div className="row">
        {ratingsList.map((item, i) => {
          return (
            <div className="col-lg-4 pl-0" key={item.id}>
              <div className="card">
                <img src={item.img} alt="image" />
                <div className="titles">
                  <h5 className="card_head ">{item.name}</h5>
                  <h6 className="card_para black_color">{item.title}</h6>
                </div>
                <p className="body_para mb-3">{item.para}</p>
                <div className="d-flex justify-content-between align-items-center">
                  <p className="mb-0">
                    {item.status ? "On" : "Off"}
                  </p>
                  <div className="custom-control custom-switch">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id={"customSwitches" + i}
                      checked={item.status}
                      onChange={() => toggleStatus(item.id)}
                    />
                    <label
                     className={`custom-control-label ${
                      item.status ? "bg-success" : "bg-secondary"
                    }`}
                      htmlFor={"customSwitches" + i}
                    ></label>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MyRatings;
