import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "../components/header";
import { singleProperty } from "../reducers/filtersInfoSlice";
import img1 from "../assets/img/home/img1.png";
import propertyLogo from "../assets/img/home/property_logo.svg";
import next_icon from "../assets/img/icon/next_icon.svg";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "./search_by_prop.scss";
import { useNavigate } from "react-router-dom";
import Footer from "../components/footer";
import AgentCard from "../components/agent_card";
const AgentProfilePage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  var filtersData = useSelector((state) => state.filtersInfo);

  const list = [
    {
      id: 1,
      img: img1,
      name: "Riverscape",
      address: "Bandlaguda, TSPA Junction",
      propertyType: "3, 4 BR Villas",
      price: "Starting from ₹4* Crores",
      logo: propertyLogo,
      boostAd: false,
    },
    {
      id: 2,
      img: img1,
      name: "Riverscape",
      address: "Bandlaguda, TSPA Junction",
      propertyType: "3, 4 BR Villas",
      price: "Starting from ₹4* Crores",
      logo: propertyLogo,
      boostAd: false,
    },
    {
      id: 3,
      img: img1,
      name: "Riverscape",
      address: "Bandlaguda, TSPA Junction",
      propertyType: "3, 4 BR Villas",
      price: "Starting from ₹4* Crores",
      logo: propertyLogo,
      boostAd: false,
    },
    {
      id: 4,
      img: img1,
      name: "Riverscape",
      address: "Bandlaguda, TSPA Junction",
      propertyType: "3, 4 BR Villas",
      price: "Starting from ₹4* Crores",
      logo: propertyLogo,
      boostAd: true,
    },
  ];
  const singlePageView = (item) => {
    dispatch(singleProperty(item));
    navigate("/one-property");
  };
  return (
    <div>
      <Header />
      <div className="allpage_banner">
        <div className="search_page">
          <div className="container">
            <p className="">
              Home : Hyderabad : Sukhii Ubuntu : AGENT : Chaten
            </p>
          </div>
        </div>
        <div className="row_top">
       <div className="container">
       <AgentCard />
       </div>
        </div>
        <div className="section_padding">
          <div className="container top_builders search_main_div">
            <h2 className="section_head text-center">
              Properties listed by Chaten
            </h2>
            <div className="row row_top">
              {list.map((item, i) => {
                return (
                  <div className="col-lg-3">
                    <div
                      className="card"
                      onClick={() => {
                        singlePageView(item);
                      }}
                    >
                      <div className="images_sec">
                        <OwlCarousel
                          items={1}
                          className="owl-theme property_car"
                          margin={20}
                          loop
                          dots={true}
                          nav
                        >
                          <div className="item">
                            <img
                              src={item.img}
                              className="main_img"
                              alt="image"
                            />
                          </div>
                          <div className="item">
                            <img
                              src={item.img}
                              className="main_img"
                              alt="image"
                            />
                          </div>
                          <div className="item">
                            <img
                              src={item.img}
                              className="main_img"
                              alt="image"
                            />
                          </div>
                        </OwlCarousel>

                        <div className="logo_image">
                          <img src={item.logo} alt="image" />
                        </div>
                      </div>

                      <div className="card_body">
                        <h5 className="card_head">{item.name}</h5>
                        <p className="grey_para">{item.address}</p>
                        <div className="d-flex justify-content-between price_sec">
                          <div>
                            <h5 className="grey_para">{item.propertyType}</h5>
                            <p className="body_para bold_para">{item.price}</p>
                          </div>
                          <img src={next_icon} />
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default AgentProfilePage;
