import React from "react";
import "./footer.scss";
import footer_logo from "../assets/img/footer_logo.svg";
import insta from "../assets/img/icon/insta.svg";
const Footer = () => {
  return (
    <div className="footer">
      <div className="container">
        <div className="footer_bg">
          <div className="row">
            <div className="col-lg-9 p-0">
              <div className="row">
                <div className="col-lg-4">
                  <img src={footer_logo} className="logo" alt="image" />
                  <p className="body_para">
                    That's why we offer a range of services that are designed to
                    simplify the home buying process and make it stress-free.
                  </p>
                  <div className="follow_us">
                    <div className="border_head">
                      <h5 className="card_head">Follow US</h5>
                    </div>
                    <div className="d-flex">
                      <img src={insta} alt="image" />
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="border_head mb-0">
                    <h5 className="card_head">Pages</h5>
                  </div>
                  <div className="d-flex">
                    <ul className="footer_list">
                      <li>About Us</li>
                      <li>Builders</li>
                      <li>Residential</li>
                      <li>Commercials</li>
                      <li>Contact US</li>
                    </ul>
                    <ul className="footer_list">
                      <li>Privacy Policy</li>
                      <li>Terms & Conditions</li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="border_head address_head mb-0">
                    <h5 className="card_head">Contact Us</h5>
                  </div>
                  <ul className="footer_list">
                    <li>we@zlatoindia.com</li>
                    <li>+91 123456 44 55</li>
                  </ul>
                  <div className="address">
                    <div className="border_head address_head mb-0">
                      <h5 className="card_head">Office Address</h5>
                    </div>
                    <ul className="footer_list">
                      <li> C94G+FHC, Manikonda Jagir, Telangana 500089</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 p-0">
                <div className="card callto_action">
                <h5 className="card_head white_color">Become a Channel Partner</h5>
                <p className="body_para white_color">
                ZlatoProperty is a premier real estate platform that provides comprehensive 
                </p>
                <button className="third_btn footer_btn">
                Contact US
                </button>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Footer;
