import React, { useState } from 'react'
import previous_icon from '../../assets/img/icon/properties/prev_icon.svg'
import './property.scss';
import down_arrow from '../../assets/img/icon/enquiries/down_Arrow.svg';
import MapModal from './mapModal';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { userType } from '../../reducers/postPropertySlice';
import OwnerShipButtons from './ownerShipButtons';
import PropertyType from './propertyType';
import HouseFacing from './houseFacing';
import RoomsAndBalconies from './roomsAndBalconies';
import FloorNo from './floorNo';
import TotalFloors from './totalFloors';
import PropertyArea from './propertyArea';
import PropertyPrice from './propertyPrice';
import PropertyFeatures from './propertyFeatures';
import CarParking from './carParking';
import Ameneties from './ameneties';
import ImageSection from './imageSection';
import TransactionType from './transactionType';
const PostProperty = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showMap, setShowMap] = useState(false);
  const pathsInfo = useSelector((state) => state.linkPathInfo);
  const linkValue = parseInt(pathsInfo?.linkValue);
  const properties = useSelector((state) => state.postProperties);
  const selectButton = properties?.userType;
  const [isChecked, setIsChecked] = useState(false);
  const openMap = () => {
    setShowMap(true);
  }
  const saveUserType = (value) => {
    dispatch(userType(value));
  }
  const backToProperty = () => {
    if (linkValue == 1) {
      console.log("came to here")
      navigate('/dashboard')
    } else if (linkValue == 2) {
      navigate('/enquiries')
    } else if (linkValue == 3) {
      navigate('/properties')
    } else if (linkValue == 4) {
      navigate('/myprofile')
    } else {
      navigate('/properties')
    }
  }
  
  const checkHandler =(event)=>{
    setIsChecked(event.target.checked);
  }
  console.log(selectButton, properties.ownerShipType, "but")
  return (
    <div className="main_div_padding property_page">
    <div>
      <div className='row align-items-center person_info'>
        <div className='col-lg-1'>
          <button style={{ border: 'none', background: 'none' }} onClick={backToProperty}>
            <img src={previous_icon} alt="previous_icon" />
          </button>
        </div>
        <div className='col-lg-9 add_property'>
          <h5>Adding Property for Sell</h5>
          <p>SellProperty On GoldBrick</p>
        </div>
        <div className='col-lg-2'>
        </div>
      </div>
      <div className='property_views' id='scroll_style'>
        <div className='row' style={{ paddingBottom: '20px' }}>
          <div className='col-lg-1'></div>
          <div className='col-lg-10'>
            <div className='personal_detail'>
              <div className='d-flex align-items-center justify-content-between' id="accordion">
                <h5>Personal Details</h5>
                <button style={{ border: 'none', background: 'none' }}
                  data-toggle="collapse" data-target={"#collapseOne"} aria-expanded="true"
                  aria-controls="collapseOne">
                  <img src={down_arrow} alt="view-btn" />
                </button>
              </div>
              <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                <div className='row' style={{ marginBottom: '10px', paddingTop: '10px' }}>
                  <p className='labels'>I am<span style={{ color: '#EE2F2F' }}>*</span></p>
                </div>
                <div className='d-flex' style={{ paddingBottom: '20px' }}>
                  <button type="button" className={selectButton === 'owner' ? 'selected_value' : 'unselected_btn'}
                    onClick={() => saveUserType('owner')}>Owner</button>
                  <button type="button" className={selectButton === 'agent' ? 'selected_value' : 'unselected_btn'}
                    onClick={() => saveUserType('agent')}>Agent</button>
                  <button type="button" className={selectButton === 'builder' ? 'selected_value' : 'unselected_btn'}
                    onClick={() => saveUserType('builder')}>Builder</button>
                </div>
                <div className='row input_tabs'>
                  <div class="col-lg-4 form-group input_tabs">
                    <label for="formGroupExampleInput" className='labels'>Name<span style={{ color: '#EE2F2F' }}>*</span></label>
                    <input type="text" class="form-control" id="username" />
                  </div>
                  <div class="col-lg-4 form-group input_tabs">
                    <label for="formGroupExampleInput" className='labels'>Contact Mobile<span style={{ color: '#EE2F2F' }}>*</span></label>
                    <input type="text" class="form-control" id="mobile" />
                  </div>
                  <div class="col-lg-4 form-group input_tabs">
                    <label for="formGroupExampleInput" className='labels'>Email<span style={{ color: '#EE2F2F' }}>*</span></label>
                    <input type="text" class="form-control" id="email" />
                  </div>
                </div>
                <div className='ownereshipes'>
                  <div className='row' style={{ paddingBottom: '10px' }}>
                    <p className='labels'>Ownership Type<span style={{ color: '#EE2F2F' }}>*</span></p>
                  </div>
                  <div className='d-flex' style={{ paddingBottom: '20px' }}>
                    <OwnerShipButtons />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-lg-1'></div>
        </div>
        <div className='row property_dtl'>
          <div className='col-lg-1'></div>
          <div className='col-lg-10'>
            <div className='property_details'>
              <div className='d-flex align-items-center justify-content-between' id="accordion">
                <h5>Property Details</h5>
                <button style={{ border: 'none', background: 'none' }}
                  data-toggle="collapse" data-target={"#collapseTwo"} aria-expanded="true"
                  aria-controls="collapseTwo">
                  <img src={down_arrow} alt="view-btn" />
                </button>
              </div>
              <div id="collapseTwo" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                <div className='property_choose'>
                  <div className='row property_type'>
                    <p>Your Property Type</p><span style={{ color: '#EE2F2F' }}>*</span>
                    <PropertyType />
                  </div>
                </div>
                <div className='house_facing'>
                  <p>House facing</p>
                  <HouseFacing />
                </div>
                <div className='property_type'>
                  <div className='locality_text'>
                    <p style={{ paddingBottom: '10px' }}>Where is the property located?</p>
                    <p>Locality/Project<span style={{ color: '#EE2F2F' }}>*</span></p>
                  </div>
                  <div className='row locality'>
                    <div className='col-lg-12' style={{ paddingLeft: '0px' }}>
                      <input type="text" class="form-control" id="locality" onClick={openMap} />
                    </div>
                  </div>
                </div>
                <div className='locality_desc'>
                  <p>Write Few Words about Locality </p>
                  <div className='row'>
                    <div className='col-lg-12' style={{ paddingLeft: '0px' }}>
                      <div class="form-group">
                        <textarea class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
                      </div>
                    </div>
                  </div>

                </div>
                <div className='rooms_balconies'>
                  <RoomsAndBalconies />
                </div>
                <div className='floor_info'>
                  <p className='common_color'>Floor No<span style={{ color: '#EE2F2F' }}>*</span></p>
                  <FloorNo />
                </div>
                <div className='total_floor_info'>
                  <TotalFloors />
                </div>
                <div className='saleable_area'>
                  <div className='property_para'>
                    <p className=''>Tell us your Property Availability<span style={{ color: '#EE2F2F' }}>*</span></p>
                  </div>
                  <PropertyArea />
                </div>
                <div className='trans_info'>
                  <TransactionType /> 
                </div>
                <div className='property_price'>
                  <div className='row'>
                    <div className='checkbox_input'>
                      <p className='common_color'>I don't want to publish price</p>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" 
                        onChange={checkHandler}/>
                        <label class="form-check-label ask_me_text" for="defaultCheck1">
                          Ask me
                        </label>
                      </div>
                    </div>
                  </div>
                  {!isChecked?<PropertyPrice />:''}
                </div>
              </div>
            </div>
          </div>
          <div className='col-lg-1'></div>
        </div>
        <div className='row property_features'>
          <div className='col-lg-1'></div>
          <div className='col-lg-10'>
            <div className='property_details'>
              <div className='d-flex align-items-center justify-content-between' id="accordion">
                <h5>Property Features</h5>
                <button style={{ border: 'none', background: 'none' }}
                  data-toggle="collapse" data-target={"#collapseThree"} aria-expanded="true"
                  aria-controls="collapseThree">
                  <img src={down_arrow} alt="view-btn" />
                </button>
              </div>
              <div id="collapseThree" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                <div className='additional_text'>
                  <p className='common_color'>Additional Furnishing</p>
                  <PropertyFeatures />
                  <p className='common_color'>Choose Amenities</p>
                  <Ameneties />
                  <CarParking />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='row image_section'>
            <div className='col-lg-1'></div>
              <div className='col-lg-10'>
                <div className='property_details'>
                  <div className='d-flex align-items-center justify-content-between' id="accordion">
                    <h5>Image Section</h5>
                    <button style={{ border: 'none', background: 'none' }}
                      data-toggle="collapse" data-target={"#collapseFour"} aria-expanded="true"
                      aria-controls="collapseFour">
                      <img src={down_arrow} alt="view-btn" />
                    </button>
                  </div>
                  <div id="collapseFour" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                  <ImageSection  />
                </div>
                </div>
              </div>
        </div>
        <div className='row privacy_policy'>
          <div className='col-lg-1'></div>
          <div className='col-lg-6'>
            <div className='exlusive_agree'>
              <div class="form-check first_check">
                <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                <label class="form-check-label" for="defaultCheck1">
                  I am posting this property 'exclusively' on GoldBrick
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                <label class="form-check-label" for="defaultCheck1">
                I am the owner/I have the authority to post this property.I agree not to provide
                incorrect property information or state a discriminatory preference.In case, the
                information does not comply with Magicbricks terms, Magicbricks.com has the right
                to edit/remove the property from their site.
                </label>
              </div>
            </div>
          </div>
          <div className='col-lg-5'></div>
        </div>
      </div>
      <div className='row'>
        <div className='col-lg-1'></div>
        <div className='col-lg-10'>
          <div className='publish_footer'>
            <di className='row flex_class justify-content-between publish_draft'>
              <div className='col-lg-5'>
                <p>Step No</p>
                <p>Personal Details</p>
              </div>
              <div className='col-lg-1'></div>
              <div className='col-lg-4 justify-content-end pubish_buttons'>
                <button className='draft_btn'>Save Draft</button>
                <button className='publish_btn'>Publish</button>
              </div>
            </di>
          </div>
        </div>
        <div className='col-lg-1'></div>
      </div>
    </div>
    {showMap ?
      <MapModal showMap={showMap} closeMap={() => setShowMap(false)} /> : ''
    }
  </div>
  )
}

        export default PostProperty