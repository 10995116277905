import React from 'react'
import { carParkingNo } from '../../reducers/postPropertySlice';
import { useDispatch, useSelector } from 'react-redux';
import './propertyType.scss';
const CarParking = () => {
    const carParkings=['1','2','3','4','5','6+'];
    const carParks = useSelector((state) => state.postProperties)
    const selectedCarParking = carParks?.carParkingNo;
    const dispatch = useDispatch();
    const handleCarParkingsNo =(index)=>{
        dispatch(carParkingNo(index));
    }
  return (
    <div className='row roomsAndBalconies floor_base'>
      <div className='col-lg-5' style={{paddingLeft:'0px'}}>
        <p className='common_color'>Car Parking</p>
            {carParkings.map((item, index) => (  
            <button
                key={index}
                type="button"
                className={selectedCarParking === index ? 'selected_value' : 'unselected_btn'}
                onClick={() => handleCarParkingsNo(index)}
            >
                {item}
            </button>
            ))}
        </div>
    </div>
  )
}

export default CarParking