import React, { useState } from 'react'
import { transType } from '../../reducers/postPropertySlice';
import { useDispatch } from 'react-redux';
import './propertyType.scss';
const TransactionType = () => {
    const dispatch = useDispatch();
    const [buttons, setButtons] = useState([
        { name: 'Under Construction', selected: false },
        { name: 'Ready To Move', selected: false },
    ]);
    const [availability, setAvailability] = useState([
        { name: 'Month', selected: false },
        { name: 'Year', selected: false },
    ]);
    const handleButtonClick = (index, name) => {
        const updatedButtons = buttons.map((button, i) => {
            if (i === index) {
                dispatch(transType(name));
                return { ...button, selected: true };
            } else {
                return { ...button, selected: false };
            }
        });
        setButtons(updatedButtons);
    };
    const handleAvailability = (index, name) => {
        const updatedButtons = availability.map((button, i) => {
            if (i === index) {
                dispatch(transType(name));
                return { ...button, selected: true };
            } else {
                return { ...button, selected: false };
            }
        });
        setAvailability(updatedButtons);
    };
    return (
        <div className='row transaction_type roomsAndBalconies'>
            <div className='col-lg-5' style={{ paddingLeft: '0px' }}>
            <p className='common_color'>Transaction Type, Property Availability<span style={{ color: '#EE2F2F' }}>*</span></p>
                {buttons.map((button, index) => (
                    <button
                        key={index}
                        type="button"
                        className={button.selected ? 'selected_value' : 'unselected_btn'}
                        onClick={() => handleButtonClick(index, button.name)}
                    >
                        {button.name}
                    </button>
                ))}
            </div>
            <div className='col-lg-5' style={{ paddingLeft: '0px' }}>
            <p className='common_color'>Availability from</p>
                {availability.map((button, index) => (
                    <button
                        key={index}
                        type="button"
                        className={button.selected ? 'selected_value' : 'unselected_btn'}
                        onClick={() => handleAvailability(index, button.name)}
                    >
                        {button.name}
                    </button>
                ))}
            </div>
        </div>
    )
}

export default TransactionType