import React from 'react'
import TopNavigation from './topNavigation'
import SideNavigation from './sideNavigation'

const Layout = (props) => {
  const {children} = props;
  return (
    <div className="layout">
      <header className="header">
        <TopNavigation />
      </header>
      <aside className="aside">
        <SideNavigation />
      </aside>
      <main id="main">{children}</main>
    </div>
 )
}

export default Layout