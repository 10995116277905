import React, { useState } from 'react'
import accumulator from '../../assets/img/icon/properties/features/accumulator 1.svg'
import car from '../../assets/img/icon/properties/features/car 1.svg'
import cash_machine from '../../assets/img/icon/properties/features/cash-machine 1.svg'
import centralized from '../../assets/img/icon/properties/features/centralized 1.svg'
import chess from '../../assets/img/icon/properties/features/chess 1.svg'
import compass from '../../assets/img/icon/properties/features/compass 1.svg'
import crew from '../../assets/img/icon/properties/features/crew 1.svg'
import dumbbell from '../../assets/img/icon/properties/features/dumbbell 1.svg'
import elevator from '../../assets/img/icon/properties/features/elevator 1.svg'
import golf from '../../assets/img/icon/properties/features/golf-bag 1.svg'
import home from '../../assets/img/icon/properties/features/home 1.svg'
import horse_ride from '../../assets/img/icon/properties/features/horse-ride 1.svg'
import hospital from '../../assets/img/icon/properties/features/hospital 1.svg'
import lake from '../../assets/img/icon/properties/features/lake 1.svg'
import minang_house from '../../assets/img/icon/properties/features/minangkabau-house 1.svg'
import mobile_phone from '../../assets/img/icon/properties/features/mobile-phone 1.svg'
import restaurant from '../../assets/img/icon/properties/features/restaurant 1.svg'
import rural from '../../assets/img/icon/properties/features/rural 1.svg'
import shcool from '../../assets/img/icon/properties/features/school 1.svg'
import security from '../../assets/img/icon/properties/features/security-guard 1.svg'
import shopping_mall from '../../assets/img/icon/properties/features/shopping-mall 1.svg'
import sports from '../../assets/img/icon/properties/features/sports 1.svg'
import swimming_pool from '../../assets/img/icon/properties/features/swimming-pool 1.svg'
import running_track from '../../assets/img/icon/properties/features/running-track 1.svg'
import './propertyType.scss';
const Ameneties = () => {
    const features = [
        {
            id: 1,
            name: 'Car parking',
            src: car
        },
        {
            id: 2,
            name: 'Power backup',
            src: accumulator
        },
        {
            id: 3,
            name: '24 x 7 Security',
            src: security
        },
        {
            id: 4,
            name: 'Lift',
            src: elevator
        },
        {
            id: 5,
            name: 'Swimming Pool',
            src: swimming_pool
        },
        {
            id: 6,
            name: 'Landscaped Gardens',
            src: rural
        },
        {
            id: 7,
            name: 'Gym',
            src: dumbbell
        },
        {
            id: 8,
            name: "Children's Play area",
            src: horse_ride
        },
        {
            id: 9,
            name: 'Club house',
            src: minang_house
        },
        {
            id: 10,
            name: 'Cafeteria',
            src: restaurant
        },
        {
            id: 11,
            name: 'Golf course',
            src: golf
        },
        {
            id: 12,
            name: 'Multipurpose room',
            src: centralized
        },
        {
            id: 13,
            name: 'Sports facility',
            src: sports
        },
        {
            id: 14,
            name: 'Rain water investing',
            src: lake
        },
        {
            id: 15,
            name: 'Intercom',
            src: mobile_phone
        },
        {
            id: 16,
            name: 'Jogging track',
            src: running_track
        },
        {
            id: 17,
            name: 'Indoor games',
            src: chess
        },
        {
            id: 18,
            name: 'Maintenance staff',
            src: crew
        },
        {
            id: 19,
            name: 'Staff quarte',
            src: home
        },
        {
            id: 20,
            name: 'Vaastu complaint',
            src: compass
        },
        {
            id: 21,
            name: 'Shopping mall',
            src: shopping_mall
        },
        {
            id: 22,
            name: 'School',
            src: shcool
        },
        {
            id: 23,
            name: 'Hospital',
            src: hospital
        },
        {
            id: 24,
            name: 'ATM',
            src: cash_machine
        },
    ]
    const [selectedImages, setSelectedImages] = useState([]);

    const handleImageClick = (id) => {
        if (selectedImages.includes(id)) {
            setSelectedImages(selectedImages.filter(item => item !== id));
        } else {
            setSelectedImages([...selectedImages, id]);
        }
    };
    console.log(selectedImages, "images")
    return (
        <div className="row image-grid features_func">
            {features.map((item) => {
                return (
                    <div className='col-lg-2 featuers' style={{ paddingLeft: '0px' }}>
                        <button style={{ border: 'none', background: 'none' }}>
                            <div
                                key={item.name}
                                className={selectedImages.includes(item.id) ? 'selected_property' : 'unselected_property'}
                                onClick={() => handleImageClick(item.id)}
                            >
                                <img src={item.src} alt={item.name} />
                            </div>
                        </button>
                        <div className="featuer_name">{item.name}</div>
                    </div>
                )
            })}

        </div>
    )
}

export default Ameneties