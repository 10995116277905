import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../components/header";
import "./one_property.scss";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { mainTabValue, mapSelectedTab } from "../../reducers/filtersInfoSlice";
import plan1 from "../../assets/img/property1/plan1.png";
import plan2 from "../../assets/img/property1/plan2.png";
import Footer from "../../components/footer";
import w_m from "../../assets/img/property1/washing_m.png";
import AgentCard from "../../components/agent_card";
import RelatedProjects from "../../components/related_projects";
import RequestDemo from "../../components/requestDemo";
import CallToAction from "../../components/call_to_action";
import ImagesModal from "../../components/images_popup";
import $ from "jquery";
const OneProperty = () => {
  var filtersData = useSelector((state) => state.filtersInfo);
  const singlePropertyData = filtersData?.singleProperty;
  const mapTab = filtersData?.mapSelectedTab;
  console.log(singlePropertyData);
  const dispatch = useDispatch();

  const map_tabs = [
    {
      id: 1,
      name: "Schools",
      to: "schools",
    },
    {
      id: 1,
      name: "Restaurants",
      to: "restaurants",
    },
    {
      id: 1,
      name: "Hospitals",
      to: "hospitals",
    },
    {
      id: 1,
      name: "ATM’s",
      to: "ATms",
    },
    {
      id: 1,
      name: "Shopping Malls",
      to: "shoppingMalls",
    },
  ];
  const mapTabChange = (tab) => {
    dispatch(mapSelectedTab(tab));
  };
  const [imagesModalStatus, setImagesModalStatus] = useState(false);
  const [imagesModal, setImagesModal] = useState(false);
  const getImagePopup = () => {
    setImagesModalStatus(true);
    setImagesModal(true);
  };
    async function copyToClip() {
      await navigator.clipboard.writeText(window.location.href);
      alert("Copied");
  }
  return (
    <div>
      <Header />
      <div className="allpage_banner">
        {/* {singlePropertyData != null ? ( */}
        <div className="one_property">
          <div className="search_page">
            <div className="container">
              <p className="">Home : Hyderabad : Sukhii Ubuntu</p>
            </div>
          </div>
          <div className="container">
            <div className="row row_top">
              <div className="col-lg-9 p-0">
                <div className="d-flex justify-content-beween">
                  <div className="d-flex">
                    <img src={singlePropertyData?.logo} alt="logo" />
                    <div className="one_property_head_div">
                      <h1 className="one_property_head">
                        {singlePropertyData?.name}
                      </h1>
                      <p className="body_para">
                        {singlePropertyData?.address +
                          " | " +
                          singlePropertyData?.price +
                          " | " +
                          singlePropertyData?.propertyType}
                      </p>
                    </div>
                  </div>
                  <p class="clipboard" onClick={()=>{
                    copyToClip()
                  }}>Share</p>
                </div>
                <div className="images">
                  <div className="images_sec">
                    <OwlCarousel
                      items={1}
                      className="owl-theme property_car"
                      margin={20}
                      loop
                      dots={true}
                      nav
                    >
                      <div
                        className="item"
                        onClick={() => {
                          getImagePopup();
                        }}
                      >
                        <img
                          src={singlePropertyData?.img}
                          className="main_img"
                          alt="image"
                        />
                      </div>
                      <div className="item">
                        <img
                          src={singlePropertyData.img}
                          className="main_img"
                          alt="image"
                        />
                      </div>
                      <div className="item">
                        <img
                          src={singlePropertyData.img}
                          className="main_img"
                          alt="image"
                        />
                      </div>
                    </OwlCarousel>
                  </div>
                </div>
                <div className="project_high project_high_div">
                  <h2 className="card_head">Project Highlights</h2>
                  <div className="row single_row">
                    <div className="col-lg-2 pl-0">
                      <p className="body_para">Price</p>
                      <h2 className="card_head m-0">45,000 per sft</h2>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-2 pl-0">
                      <p className="body_para">Development Size</p>
                      <h2 className="card_head m-0">5 Acres</h2>
                    </div>
                    <div className="col-lg-2 pl-0">
                      <p className="body_para">Project Type </p>
                      <h2 className="card_head m-0">Villas</h2>
                    </div>

                    <div className="col-lg-2 pl-0">
                      <p className="body_para">Bedrooms</p>
                      <h2 className="card_head m-0">3, 4 BHK</h2>
                    </div>
                    <div className="col-lg-2 pl-0">
                      <p className="body_para">Status</p>
                      <h2 className="card_head m-0">Ready to move</h2>
                    </div>
                    <div className="col-lg-2 pl-0">
                      <p className="body_para">Ownership Type</p>
                      <h2 className="card_head m-0">Freehold</h2>
                    </div>
                  </div>
                </div>
                <div className="project_high project_high_div project_about_div">
                  <h2 className="card_head prop_card_head">
                    About Prestige Dew Drops
                  </h2>
                  <p className="body_para">
                    Just 25 villas set in 5 delightfully landscaped acres.
                    Villas that spell luxury and fi ne living in every built-up
                    square foot.<br></br> Situated adjacent to Prestige North
                    West County and a 5-minute walk from Angsana Oasis Spa &
                    Resort, just off Doddaballapura Main Road, in Bengaluru.
                  </p>
                  <p className="body_para">
                    Accessing essential conveniences and comforts is easy at
                    Prestige Dewdrops. Yelahanka and its vicinity are among the
                    fastest developing regions in North Bengaluru. Leading
                    educational institutions, high profi le shopping, leisure
                    and entertainment, and top quality healthcare are all to be
                    found within a radius of a few kilometres. The airport is
                    just half an hour away and central Bengaluru is easily
                    accessible by Bellary Road.
                  </p>
                  <p className="body_para">
                    Your villa at Prestige Dewdrops is cosily tucked away in a
                    delightful landscape. Secluding you from the world outside
                    and enveloping you in Nature’s tranquil embrace.
                  </p>
                  <p className="body_para mb-0">
                    The air is fresh. Th e environs are green, picturesque and a
                    feast for the eyes. Th e internal roads are broad, well laid
                    and intertwined with leafy walkways and specially designed
                    communal spaces. Life is perfect.
                  </p>
                </div>
                <div className="project_high project_high_div">
                  <div className="row">
                    <div className="col-lg-2 pl-0">
                      <p className="body_para">Balconies </p>
                      <h2 className="card_head m-0">5 Acres</h2>
                    </div>
                    <div className="col-lg-2 pl-0">
                      <p className="body_para">Total Floors </p>
                      <h2 className="card_head m-0">Villas</h2>
                    </div>

                    <div className="col-lg-3 pl-0">
                      <p className="body_para">Furnished Status </p>
                      <h2 className="card_head m-0">3, 4 BHK</h2>
                    </div>
                    <div className="col-lg-2 pl-0">
                      <p className="body_para">Balconies </p>
                      <h2 className="card_head m-0">Ready to move</h2>
                    </div>
                  </div>
                </div>
                <div className="project_high project_high_div">
                  <h2 className="card_head">Masterplan</h2>
                  <div>
                    <div className="row">
                      <div className="col-lg-3 pl-0">
                        <div className="plan_div">
                          <img src={plan2} alt="image" />
                        </div>
                      </div>
                      <div className="col-lg-3">
                        <div className="plan_div">
                          <img src={plan1} alt="image" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="project_high project_high_div">
                  <h2 className="card_head prop_card_head">
                    Property Features
                  </h2>
                  <div className="d-flex ametites">
                    <div className="icon_div text-center">
                      <img src={w_m} />
                      <p className="body_para mb-0">Washing machine</p>
                    </div>
                    <div className="icon_div text-center">
                      <img src={w_m} />
                      <p className="body_para mb-0">wifi connection</p>
                    </div>
                  </div>
                </div>
                <div className="project_high project_high_div">
                  <h2 className="card_head">Nearby</h2>
                  <div>
                    <ul
                      className="nav nav-tabs search_tabs one_prop_page_tab"
                      id="myTab"
                      role="tablist"
                    >
                      {map_tabs.map((mainTab) => {
                        return (
                          <li key={mainTab.id} className="nav-item">
                            <a
                              className={
                                "nav-link" +
                                (mapTab == mainTab.to ? " active" : "")
                              }
                              href={"#" + mapTab}
                              role="tab"
                              aria-controls="home"
                              data-bs-toggle="tab"
                              onClick={() => mapTabChange(mainTab.to)}
                            >
                              {mainTab.name}
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                    <div className="tab-content p-0 top_builders search_main_div tab_padding">
                      <div
                        className="tab-pane active"
                        id={mapTab}
                        role="tabpanel"
                        aria-labelledby="home-tab"
                      >
                        <div className="row">
                          <div className="col-lg-12">{mapTab}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 pr-0">
                <div className="sticky_left">
                  <RequestDemo />
                  <div className="row_top">
                    <CallToAction btn_text="Get Agent Support" />
                  </div>
                </div>
              </div>
            </div>
            <div className="section_padding pt-0 row_top">
              <h2 className="card_head prop_card_head">Listed by</h2>
              <AgentCard />
            </div>
            <div className="section_padding pt-0">
              <div className="row">
                <div className="col-lg-9 pl-0">
                  <h2 className="card_head prop_card_head">
                    Relevant Projects
                  </h2>
                  <RelatedProjects />
                </div>
                <div className="col-lg-3"></div>
              </div>
            </div>
          </div>
        </div>
        {/* ) : (
          "No data"
        )} */}
      </div>
      {imagesModalStatus ? (
        <ImagesModal
          showImageModal={imagesModal}
          closeImageModal={() => setImagesModal(false)}
        />
      ) : (
        ""
      )}
      <Footer />
    </div>
  );
};
export default OneProperty;
